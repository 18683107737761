import { getFlags } from '@/components/FeatureFlags/api';
export const isBrowser = typeof window !== 'undefined';

export const checkAndSetFeatureFlag = async (
  featureFlagName: string,
  localStorageKey: string
): Promise<boolean> => {
  //@ts-ignore
  const { flags } = await getFlags(
    `${process.env.NEXT_PUBLIC_FEATURE_FLAGS_URL}`
  );
  const isFeatureActive =
    flags?.some(
      ({ active, name }: { active: boolean; name: string }) =>
        name === featureFlagName && !!active
    ) || localStorage?.getItem(featureFlagName) === 'true';

  localStorage?.setItem?.(localStorageKey, isFeatureActive ? 'true' : 'false');
  return isFeatureActive;
};

export const getFeatureFlagFromStorage = (localStorageKey: string): boolean => {
  let item = localStorage?.getItem?.(localStorageKey);
  return item === 'true';
};
