import updateQuestionnaire from '@/api/mutations/updateQuestionnaire';
import {
  setClosureTime,
  setIsCaseActive,
  setIsQuestionnaireActive,
  setQuestionnaireCompletedAt,
} from '../slices/questionnaire';
import { AppThunk } from '../store';
import { getFeatureFlagFromStorage } from '@/components/FeatureFlags/utils/checkAndSetFeatureFlag';

const submitCase = (): AppThunk => async (dispatch) => {
  const IS_USING_QUESTIONNAIRE_API = getFeatureFlagFromStorage(
    'IS_USING_QUESTIONNAIRE_API'
  );
  if (IS_USING_QUESTIONNAIRE_API) {
    const dateNow = new Date().toISOString();
    dispatch(setIsQuestionnaireActive(false));
    // Note: Ideally the completedAt date should be set
    // by the backend i.e after the response is received update completedAt state.
    dispatch(setQuestionnaireCompletedAt(dateNow));
    await updateQuestionnaire();
  } else {
    dispatch(setIsCaseActive(false));
    const {
      data: {
        updateCase: { closureTime },
      },
    } = (await updateQuestionnaire()) as { data: any };
    dispatch(setClosureTime(closureTime));
  }
};

export default submitCase;
