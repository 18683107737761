import { EntityType } from './basicTypes';

export enum ProccessStep {
  Closure = 'CLOSURE',
  Intake = 'INTAKE',
}
export interface CaseType {
  id: string;
  label: string;
  indicators: string[];
  modules: string[];
  triggeredModules?: string[];
  isSessionOnly: boolean;
  entityType: EntityType;
  text?: string;
  processStep: ProccessStep;
}

export interface CaseTypeSlice {
  entities: {
    [key: string]: CaseType;
  };
  ids: string[];
  suggested: string[];
  selected: string[];
  isLoading?: boolean;
}
