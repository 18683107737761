import {
  envApiKey,
  isDevelopment,
  isIntegration,
  isPreviewEnv,
} from '@/constants/env';
import { getRootPathRequiresAuth } from '@/utils/router';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import Cookie from 'js-cookie';
import {
  setAccessPermissions,
  setCurrentUser,
  setHasAuthenticated,
} from '@/store/slices/config';
import { store } from '@/store';
import authorizeUser from '@/api/auth/authorizeUser';
import { AccessPermissions } from '@/store/types/config';

const useAuthenticate = async () => {
  const router = useRouter();

  const handleAuthenticate = async () => {
    const routeRequiresAuth = getRootPathRequiresAuth(router);
    const hasJWT = !!Cookie.get('jwt');
    const shouldUseApiKey =
      (!!router.query.useApiKey && isIntegration) ||
      isDevelopment ||
      isPreviewEnv;

    if (shouldUseApiKey || hasJWT) {
      const { data } = await authorizeUser();
      if (shouldUseApiKey) {
        localStorage.setItem('apiKey', envApiKey as string);
        store.dispatch(setCurrentUser(data.user));
      }
      store.dispatch(
        setAccessPermissions(data.permissions as AccessPermissions[])
      );
      store.dispatch(setHasAuthenticated(true));
      return;
    }

    if (!hasJWT && routeRequiresAuth) {
      // We only want to redirect if the user is on a page that requires authentication
      // because we could be on a /auth page and redirecting would cause an infinite loop.
      router.push(`/auth/verify?redirect=${window.location.href}`);
    }
  };

  useEffect(() => {
    if (router.isReady) {
      handleAuthenticate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  return null;
};

export default useAuthenticate;
