import saveAnswer, { AnswerSaveData } from '../mutations/saveAnswer';
import updateQuestionnaire from '../mutations/updateQuestionnaire';
import SaveDataTypes from '../types/saveDataTypes';
import updateDescription from '../mutations/updateDescription';

export type SaveData =
  | { type: SaveDataTypes.Case; data?: void }
  | { type: SaveDataTypes.Answer; data: AnswerSaveData }
  | { type: SaveDataTypes.Description; data?: void };

const saveData = ({ type, data }: SaveData) => {
  switch (type) {
    case SaveDataTypes.Answer:
      saveAnswer(data);
      break;
    case SaveDataTypes.Case:
      updateQuestionnaire();
      break;
    case SaveDataTypes.Description:
      updateDescription();
      break;
    default:
      break;
  }
};

export default saveData;
