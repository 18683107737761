import client from '@/api/client';
import {
  GetInitialData,
  GetInitialQuestionnaireData,
  // @ts-ignore
} from '@/api/gql/queries.gql';
import { isUsingFixtures as IS_USING_FIXTURES } from '@/constants/env';
import { RawInitialData } from '../types/initialData';
import { fetchUser } from '../auth/fetchUser';
import { getMockApiInitialData } from '@/__mocks__/api/mockApiInitialData';
import initialTestState from '@/__mocks__/testState/initialTestState';
import { getFeatureFlagFromStorage } from '@/components/FeatureFlags/utils/checkAndSetFeatureFlag';

export const getInitialData = async ({
  projectId,
}: {
  projectId: string;
}): Promise<{
  data?: RawInitialData;
  apiKey?: string;
  error?: any;
}> => {
  const fetchData = async () => {
    const IS_USING_QUESTIONNAIRE_API = getFeatureFlagFromStorage(
      'IS_USING_QUESTIONNAIRE_API'
    );

    if (IS_USING_FIXTURES) {
      return { data: getMockApiInitialData(initialTestState) };
    }

    const { data } = await client.query({
      query: IS_USING_QUESTIONNAIRE_API
        ? GetInitialQuestionnaireData
        : GetInitialData,
      variables: {
        [IS_USING_QUESTIONNAIRE_API ? 'questionnaireId' : 'caseId']: projectId,
      },
    });

    const getUserDetails = await fetchUser();

    return {
      data: {
        ...data,
        getUserDetails,
      },
    };
  };

  try {
    return await fetchData();
  } catch (error: any) {
    return { error };
  }
};

export default getInitialData;
