import { Question } from '@/store/types/question';
import {
  getFormattedAdvice,
  getFormattedCase,
  getFormattedCaseType,
  getFormattedDescription,
  getFormattedIndicator,
  getFormattedModule,
  getFormattedModuleGroup,
  getFormattedQuestion,
  getFormattedTip,
  getFormattedUser,
  getFormattedIntegrations,
  getFormattedQuestionnaire,
} from './entityTransforms';
import { CaseType } from '@/store/types/caseType';
import { Tip } from '@/store/types/tip';

import { Indicator } from '@/store/types/indicator';
import { Advice } from '@/store/types/advice';
import {
  AdaptedEntities,
  FormattedCase,
  FormattedDescription,
  FormattedQuestionnaire,
} from '@/api/types/initialData';
import { Module, ModuleGroup } from '@/store/types/module';
import { User } from '@/api/types/user';
import { Integration } from '@/store/types/integrations';

enum Typenames {
  Question = 'Question',
  Tip = 'Tip',
  CaseType = 'CaseType',
  Module = 'Module',
  Indicator = 'Indicator',
  Advice = 'Advice',
  Case = 'Case',
  ModuleGroup = 'ModuleGroup',
  Integration = 'Integration',
}

interface Entity {
  id: string;
  __typename: string;
}

export const getFormattedEntity = <T extends Entity>(rawEntity: T) => {
  if (!rawEntity) {
    return {};
  }
  const { __typename, ...entity } = rawEntity;

  switch (__typename) {
    case Typenames.Question:
      return getFormattedQuestion(entity);
    case Typenames.Tip:
      return getFormattedTip(entity);
    case Typenames.CaseType:
      return getFormattedCaseType(entity);
    case Typenames.Indicator:
      return getFormattedIndicator(entity);
    case Typenames.Module:
      return getFormattedModule(entity);
    case Typenames.ModuleGroup:
      return getFormattedModuleGroup(entity);
    case Typenames.Advice:
      return getFormattedAdvice(entity);
    case Typenames.Integration:
      return getFormattedIntegrations(entity);
    case Typenames.Case:
      return getFormattedCase(entity);
    default:
      return {};
  }
};

export const formatEntities = (entities: Entity[]) => {
  return (
    entities?.map((entity) => {
      return getFormattedEntity(entity);
    }) || []
  );
};

const adaptApiDataEntities = ({
  questionnaireEntity,
  caseEntity,
  questions,
  caseTypes,
  tips,
  modules,
  moduleGroups,
  indicators,
  advice,
  description,
  user,
  integrations,
}: {
  questionnaireEntity: Entity;
  caseEntity: Entity;
  description: Entity;
  questions: Entity[];
  caseTypes: Entity[];
  tips: Entity[];
  modules: Entity[];
  moduleGroups: Entity[];
  indicators: Entity[];
  advice: Entity[];
  user: User;
  integrations: Entity[];
}): AdaptedEntities => {
  return {
    caseEntity: caseEntity
      ? getFormattedCase(caseEntity)
      : ({} as FormattedCase),
    description: getFormattedDescription(description) as FormattedDescription,
    questions: formatEntities(questions) as Question[],
    caseTypes: formatEntities(caseTypes) as CaseType[],
    tips: formatEntities(tips) as Tip[],
    modules: formatEntities(modules) as Module[],
    moduleGroups: formatEntities(moduleGroups) as ModuleGroup[],
    indicators: formatEntities(indicators) as Indicator[],
    advice: formatEntities(advice) as Advice[],
    user: getFormattedUser(user),
    integrations: formatEntities(integrations) as Integration[],
    questionnaireEntity: questionnaireEntity
      ? getFormattedQuestionnaire(questionnaireEntity)
      : ({} as FormattedQuestionnaire),
  };
};

export default adaptApiDataEntities;
