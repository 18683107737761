import { setDescription } from '../slices/questionnaire';
import { AppThunk } from '../store';
import saveData from '@/api/utils/saveData';
import SaveDataTypes from '@/api/types/saveDataTypes';
import { emptySuggestedAnswersStatus } from '../slices/ui';
import { getFeatureFlagFromStorage } from '@/components/FeatureFlags/utils/checkAndSetFeatureFlag';

const updateDescriptionThunk =
  (description: string): AppThunk =>
  (dispatch) => {
    const IS_USING_QUESTIONNAIRE_API = getFeatureFlagFromStorage(
      'IS_USING_QUESTIONNAIRE_API'
    );

    dispatch(setDescription(description));
    dispatch(emptySuggestedAnswersStatus());
    saveData({
      type: IS_USING_QUESTIONNAIRE_API
        ? SaveDataTypes.Description
        : SaveDataTypes.Case,
    });
  };

export default updateDescriptionThunk;
