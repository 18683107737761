import { defaultFiltersObject } from '@/store/types/admin';
import { mockOrganisation1 } from './organisationsMock';

export const adminMock = {
  questionnaires: {
    entities: {},
    ids: [],
  },
  incidents: {
    ids: [],
    entities: {},
    filters: defaultFiltersObject,
    isLoading: false,
  },
  contributors: {
    entities: {},
    ids: [],
    filters: defaultFiltersObject,
    isLoading: false,
  },
  organisations: {
    entities: {
      [mockOrganisation1.id]: { ...mockOrganisation1 },
    },
    ids: [mockOrganisation1.id],
    isUpdating: false,
    isLoading: false,
    filters: defaultFiltersObject,
  },
  users: {
    entities: {},
    ids: [],
    filters: defaultFiltersObject,
    isLoading: false,
  },
};
