import { SnackbarTypes } from '@/store/types/ui';

export const uiMock = {
  sidebarSelections: {
    description: false,
    participantsList: false,
    history: false,
  },
  printableSummaryStatus: {
    isOpen: false,
    isAutoCopy: false,
  },
  snackbarNotification: {
    message: null,
    action: null,
    type: 'info',
    disableAutoHide: false,
  },
  refreshDialogStatus: {
    isOpen: false,
  },
  descriptionPopoutWindowStatus: {
    isOpen: false,
  },
  suggestedIndicatorsStatus: {
    isLoading: false,
  },
  isRefreshingDisplay: false,
  isEditMode: true,
  summaryStepScrollPositionY: 0,
  specificsStepScrollPositionY: 190,
  suggestedAnswersFetchingStatus: {},
  hasRequestedSuggestedAnswers: false,
  hasCaseBeenCleared: false,
  organisationDialog: {
    isOpen: true,
    mode: 'CREATE',
    organisationId: null,
  },
  accessPermissions: {
    viewIncident: true,
    viewIncidents: true,
    viewContributors: true,
    viewOrganisations: true,
    viewQuestionnaires: true,
    viewUserDetail: true,
    viewClosure: true,
    updateInsurerPrimaryContact: true,
    viewUsersInCurrentOrg: true,
    viewIncidentsPerUser: true,
    viewIncidentManagerOrgs: true,
    updateIncidentManagerOrg: true,
    updateIncidentManagerPrimaryContact: true,
    viewIncidentByContributor: true,
    createIncident: true,
    viewClosedClosure: true,
    viewClosedIncident: true,
  },
};
