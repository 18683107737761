import { Typography } from '@mui/material';

export const renderSelectValue = (
  selected: unknown,
  options: Array<{ value: string; label: string }>
) => {
  if (!selected) {
    return <Typography variant='body1'>Select role</Typography>;
  }
  const selectedOption = options.find((option) => option.value === selected);
  return selectedOption ? selectedOption.label : String(selected);
};
