import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectIsContributorsListDialogOpen,
  setIsContributorsListDialogOpen,
} from '@/store/slices/ui';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContributorsTable from '../ContributorsTable/ContributorsTable';

export const ContributorsModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectIsContributorsListDialogOpen);

  const handleOnClickCancel = () => {
    dispatch(setIsContributorsListDialogOpen(false));
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleOnClickCancel}
        maxWidth='lg'
        fullWidth
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 2,
          }}
        >
          <Typography variant='h3' sx={{ fontWeight: 500 }}>
            Contributors
          </Typography>
          <IconButton aria-label='close' onClick={handleOnClickCancel}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ContributorsTable />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ContributorsModal;
