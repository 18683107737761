import { CaseType } from '@/store/types/caseType';
import { Indicator, IndicatorToCaseTypesMap } from '@/store/types/indicator';
import { Question, QuestionAnswerValue } from '@/store/types/question';
import { Tip } from '@/store/types/tip';
import { Module, ModuleGroup } from '@/store/types/module';

import { Advice } from '@/store/types/advice';
import { CaseTypeId, IndicatorId, ModuleId } from '@/store/types/basicTypes';
import { Client, Project } from '@/store/types/questionnaire';
import { User } from './user';
import { EditorSession } from '@/store/types/editor';
import { Integration } from '@/store/types/integrations';

export enum InitialDataResponseMap {
  Questions = 'getAllQuestions',
  CaseTypes = 'getAllCaseTypes',
  Indicators = 'getAllIndicators',
  Modules = 'getAllModules',
  ModuleGroups = 'getAllModuleGroups',
  Tips = 'getAllTips',
  Advice = 'getAllAdvice',
  Case = 'getCase',
  Integration = 'getAllIntegrations',
}

interface EntityWithTypename {
  id: string;
  __typename: string;
}

export type QuestionWithTypename = Question & EntityWithTypename;
export type CaseTypeWithTypename = CaseType & EntityWithTypename;
export type IndicatorWithTypename = Indicator & EntityWithTypename;
export type ModuleWithTypename = Module & EntityWithTypename;
export type TipWithTypename = Tip & EntityWithTypename;
export type AdviceWithTypename = Advice & EntityWithTypename;

export interface RawInitialData {
  getAllQuestions: any[];
  getAllCaseTypes: any[];
  getAllIndicators: any[];
  getAllModules: any[];
  getAllModuleGroups: any[];
  getAllTips: any[];
  getAllAdvice: any[];
  getCase: any;
  getDescription: any;
  getUserDetails: any;
  getAllIntegrations: any[];
  getQuestionnaire: any;
  getQuestionnaireDescription: any;
}

export interface AdaptedEntities {
  questions: Question[];
  caseTypes: CaseType[];
  indicators: Indicator[];
  modules: Module[];
  moduleGroups: ModuleGroup[];
  tips: Tip[];
  advice: Advice[];
  caseEntity?: FormattedCase;
  description: FormattedDescription;
  user: User;
  integrations: Integration[];
  questionnaireEntity?: FormattedQuestionnaire;
}

export interface RawCaseType {
  id: string;
  label: string;
  indicatorIds: string[];
  moduleIds: string[];
}

export interface FormattedCase {
  caseId: string;
  selectedCaseTypes: CaseTypeId[];
  selectedIndicators: IndicatorId[];
  dismissedIndicators: IndicatorId[];
  manuallySelectedModules: ModuleId[];
  questionValues: { [key: string]: string };
  client: Client;
  project: Project;
  isActive: boolean; // This will be used to control the questionnaire active state once closure is depricated.
  isIntakeActive: boolean; // This will be depricated and we will use isActive instead.
  startedAt: null | string;
  completedAt: null | string;
  triggeredIntegrations: string[];
  staticModuleIds?: string[];
  isDiagnosisEnabled?: boolean;
  isNotesEnabled?: boolean;
}

export interface FormattedQuestionnaire {
  projectId: string;
  questionnaireId: string;
  selectedCaseTypes: CaseTypeId[];
  selectedIndicators: IndicatorId[];
  dismissedIndicators: IndicatorId[];
  manuallySelectedModules: ModuleId[];
  questionValues: { [key: string]: string };
  client: Client;
  project: Project;
  isDiagnosisEnabled: boolean;
  isAddModulesEnabled: boolean;
  isNotesEnabled: boolean;
  staticModuleIds: string[];
  isActive: boolean;
  startedAt: string | null;
  completedAt: string | null;
  notes: string;
  label: string;
  triggeredIntegrations: string[];
}

export interface FormattedDescription {
  text: string;
  hasSuggestedIndicators: boolean;
  suggestedIndicators: IndicatorId[];
}

export interface TransformedInitialData {
  questions: {
    entities: {
      [key: string]: Question;
    };
    ids: string[];
  };
  caseTypes: {
    entities: {
      [key: string]: CaseType;
    };
    ids: string[];
  };
  indicators: {
    entities: {
      [key: string]: Indicator;
    };
    ids: string[];
  };
  modules: {
    entities: {
      [key: string]: Module;
    };
    ids: string[];
  };
  moduleGroups: {
    entities: {
      [key: string]: ModuleGroup;
    };
    ids: string[];
  };
  tips: {
    entities: {
      [key: string]: Tip;
    };
    ids: string[];
  };
  advice: {
    entities: {
      [key: string]: Advice;
    };
    ids: string[];
  };
  selectedIndicators: IndicatorId[];
  suggestedIndicators: IndicatorId[];
  selectedCaseTypes: CaseTypeId[];
  questionValues: { [key: string]: QuestionAnswerValue };
  selectedModules: ModuleId[];
  manuallySelectedModules: ModuleId[];
  indicatorToCaseTypesMap: IndicatorToCaseTypesMap;
  caseDescription: string;
  caseId: string;
  project: Project;
  client: Client;
  suggestedCaseTypes: string[];
  user: User;
  tipAliasMap: { [aliasId: string]: string };
  unMergedEntities: AdaptedEntities;
  editorSession: EditorSession | null;
  integrations: {
    entities: {
      [key: string]: Integration;
    };
    ids: string[];
  };
  triggeredIntegrations: string[];
  intakeTime: string; // NOTE: TO BE DEPRICATED
  closureTime: string | null; // NOTE: TO BE DEPRICATED
  isActive: boolean; // // Note: This field is currently being used to control the closure active state.
  isIntakeActive: boolean; // NOTE: TO BE DEPRICATED
  projectId: string;
  questionnaireId: string;
  isDiagnosisEnabled?: boolean;
  isNotesEnabled?: boolean;
  isAddModulesEnabled?: boolean;
  staticModuleIds?: string[];
  startedAt?: null | string;
  completedAt?: null | string;
  label?: string;
}

export interface SessionData {
  id: string;
  lastUpdatedAt: string;
  lastUpdatedBy: string;
  createdAt: string;
  createdBy: string;
  version: number;
  edited: {
    caseTypes: { id: string; to: CaseType | null; from: CaseType | null }[];
    modules: { id: string; to: Module | null; from: Module | null }[];
    questions: { id: string; to: Question | null; from: Question | null }[];
    tips: { id: string; to: Tip | null; from: Tip | null }[];
    advice: { id: string; to: Advice | null; from: Advice | null }[];
    indicators: {
      id: string;
      to: Indicator | null;
      from: Indicator | null;
    }[];
    moduleGroups: {
      id: string;
      to: ModuleGroup | null;
      from: ModuleGroup | null;
    }[];
  };
}
