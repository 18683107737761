import { Alert, AlertTitle, Typography } from '@mui/material';
import ConfirmationModal from '../ConfirmationModal';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectIsConfirmingReopeningQuestionnaire,
  setIsConfirmingReopenQuestionnaire,
} from '@/store/slices/ui';
import userActionInterceptor from '@/store/thunks/userActionInterceptor';
import updateSnackbarNotification from '@/store/thunks/updateSnackbar';
import { SnackbarTypes } from '@/store/types/ui';
import { getFeatureFlagFromStorage } from '../FeatureFlags/utils/checkAndSetFeatureFlag';

const ReopenQuestionnaireModal = () => {
  const IS_USING_QUESTIONNAIRE_API = getFeatureFlagFromStorage(
    'IS_USING_QUESTIONNAIRE_API'
  );
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectIsConfirmingReopeningQuestionnaire);
  const handleClose = () => dispatch(setIsConfirmingReopenQuestionnaire(false));
  const title = IS_USING_QUESTIONNAIRE_API ? 'questionnaire' : 'intake';

  const handleReopenCase = () => {
    dispatch(setIsConfirmingReopenQuestionnaire(false));
    dispatch(
      userActionInterceptor({ actions: [], shouldContinueIntake: true })
    );
    dispatch(
      updateSnackbarNotification({
        message: `The ${title} has been re-opened`,
        type: SnackbarTypes.Success,
      })
    );
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title={`Re-open ${title}`.toUpperCase()}
      confirmText='Yes, continue'
      onConfirm={handleReopenCase}
      onClose={handleClose}
      testId={`reopen-${title}-confirmation-modal`}
    >
      <Alert severity='warning' data-testid={`reopoen-${title}-warning`}>
        <AlertTitle>Warning</AlertTitle>
        <Typography>You are about to re-open the {title}</Typography>
      </Alert>
    </ConfirmationModal>
  );
};

export default ReopenQuestionnaireModal;
