import { Routes } from '@/constants/routes';
import { closureRoute, navPaths } from '@/data/navPaths';
import {
  getRootPathFromRouter,
  replaceRootPathFromPathname,
} from '@/utils/router';
import { useRouter } from 'next/router';
import { StepperNav } from './StepperNav';
import {
  selectActivePath,
  selectIsEditMode,
  setActivePath as setPathActiveAction,
} from '@/store/slices/ui';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useEffect } from 'react';
import { useFeatureFlags } from '../FeatureFlags/Provider';
import featureFlags from '@/constants/featureFlags';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box } from '@mui/material';
import { Button } from '@s-rm/react-ui-lib';

const NavBar = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const activePath = useAppSelector(selectActivePath);

  const handleChangeRoute = (rootPath: Routes) => {
    router.push(
      replaceRootPathFromPathname({ pathname: router.asPath, rootPath })
    );
  };

  useEffect(() => {
    dispatch(setPathActiveAction(getRootPathFromRouter(router)));
  }, [dispatch, router]);

  return (
    <Box display='flex' alignItems='center' gap={2}>
      {navPaths.map((route, i) => {
        const isActivePath = activePath === route.value;
        return (
          <>
            <Button
              sx={{
                borderRadius: '0',
                borderBottom: '3px solid',
                borderColor: isActivePath ? 'secondary.main' : 'transparent',
                // fontSize: '1rem',
                color: 'text.primary',
                fontWeight: 400,
              }}
              onClick={() => handleChangeRoute(route.value as Routes)}
              startIcon={route.icon}
            >
              {route.label}
            </Button>
            {i !== navPaths.length - 1 && <ChevronRightIcon />}
          </>
        );
      })}
    </Box>
  );
};
export default NavBar;
