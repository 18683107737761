import {
  Card as MUICard,
  styled,
  Divider as MUIDivider,
  InputLabel as MUIInputLabel,
  DialogTitle as MUIDialogTitle,
} from '@mui/material';
import { TextField as MUITextField } from '@s-rm/react-ui-lib';

export const Divider = styled(MUIDivider)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.palette.secondary.main};
  opacity: 0.1;
`;

export const TextFieldNoBorder = styled(MUITextField)`
  & .MuiInputBase-root {
    padding: 16px 0;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

export const Card = styled(MUICard)`
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.palette.grey[200]};
  transition: none;
  box-shadow: none;
`;

export const BoldText = styled('span')`
  font-weight: 600;
`;

export const TextField = styled(MUITextField)`
  label + & {
    margin-top: ${({ theme }) => theme.spacing(3)};
    margin-top: ${({ theme }) => theme.spacing(3)};
  }
`;

export const InputLabelRequired = styled(MUIInputLabel)`
  font-weight: 500;
  & .MuiInputLabel-asterisk {
    color: ${({ theme }) => theme.palette.error.main};
  }
`;

export const DialogTitle = styled(MUIDialogTitle)`
  padding: ${({ theme }) => theme.spacing(2)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
