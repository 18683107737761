import getInitialData from '@/api/queries/getInitialData';
import { AppThunk } from '../store';
import getSession from '@/api/queries/editor/getSession';
import { validateInitialData } from '@/utils/auth';
import { createError } from '@/utils/errorHandler';
import { setHasInitialised } from '../slices/config';
import { setProjectId } from '../slices/questionnaire';
import {
  checkAndSetFeatureFlag,
  getFeatureFlagFromStorage,
} from '@/components/FeatureFlags/utils/checkAndSetFeatureFlag';
import featureFlags from '@/constants/featureFlags';
import hydrateStoreThunk from './hydrateStore';

const initAppData =
  ({
    caseId: caseIdFromRouter,
    sessionId: sessionIdFromRouter,
  }: {
    caseId?: string;
    sessionId?: string;
  } = {}): AppThunk =>
  async (dispatch, getState) => {
    await checkAndSetFeatureFlag(
      featureFlags.isUsingNewQuestionnaireAPI,
      'IS_USING_QUESTIONNAIRE_API'
    );

    await checkAndSetFeatureFlag(
      featureFlags.isUsingStaticModules,
      'IS_USING_STATIC_MODULES'
    );

    const projectId = caseIdFromRouter ?? getState().questionnaire.projectId;
    const sessionId =
      sessionIdFromRouter ?? getState().editor.sessionInEdit?.id;
    let sessionData = null;
    const { data: rawInitialData } = await getInitialData({ projectId });

    if (sessionId) {
      const { data } = await getSession({ sessionId });
      sessionData = data;
    }

    const errorCode = validateInitialData(rawInitialData);

    const IS_USING_QUESTIONNAIRE_API = getFeatureFlagFromStorage(
      'IS_USING_QUESTIONNAIRE_API'
    );

    // TODO: handle error for when using new api
    if (errorCode && !IS_USING_QUESTIONNAIRE_API) {
      createError({ code: errorCode, displaySnackbar: true });
      return;
    }

    if (rawInitialData) {
      !IS_USING_QUESTIONNAIRE_API && dispatch(setProjectId(projectId));
      dispatch(hydrateStoreThunk({ rawInitialData, sessionData }));
      dispatch(setHasInitialised(true));
    }
  };

export default initAppData;
