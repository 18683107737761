import { Box, Container, Divider, Typography } from '@mui/material';
import logo from '../../../assets/img/polus-response.png';
import Image from 'next/image';
import { Chip, ChipVariant } from '@s-rm/react-ui-lib';
import { ProfileMenu } from './ProfileMenu';
import { useAppSelector } from '@/hooks';
import { selectSelectedCaseTypes } from '@/store/slices/caseTypes';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import {
  selectClient,
  selectClosureTime,
  selectIsDiagnosisEnabled,
  selectQuestionnaireLabel,
  selectQuestionnaireTime,
} from '@/store/slices/questionnaire';
import { formatDateTime } from '@/utils/helpers';
import { SettingsMenu } from './SettingsMenu';
import { selectIsEditMode } from '@/store/slices/ui';
import NavBar from '@/components/NavBar/NavBar';
import { NetworkMessages } from '../NetworkMessages';
import { OnlineStatusContext } from '@/context/onlineStatusContext';
import { useContext } from 'react';
import QuestionnaireSelector from './QuestionnaireSelector';

const QuestionnaireHeader = () => {
  const selectedCaseTypes = useAppSelector(selectSelectedCaseTypes);
  const { name: clientName = '' } = useAppSelector(selectClient) ?? {};
  const intakeTime = useAppSelector(selectQuestionnaireTime);
  const closureTime = useAppSelector(selectClosureTime);
  const isEditMode = useAppSelector(selectIsEditMode);
  const questionnaireLabel = useAppSelector(selectQuestionnaireLabel);
  const isDiagnosisEnabled = useAppSelector(selectIsDiagnosisEnabled);
  const { isOnline, wasOffline } = useContext(OnlineStatusContext);

  return (
    <Box pt={1} mb={3}>
      <Container maxWidth='xl'>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Box display='flex' alignItems='end' gap={2}>
            <Image src={logo} alt='asm-logo' height={30} />
            {isEditMode && (
              <Typography
                fontStyle='italic'
                fontWeight={300}
                fontSize='1.1rem'
                mb='-1px'
              >
                Edtior mode
              </Typography>
            )}
          </Box>
          <Box>
            <QuestionnaireSelector />

            <ProfileMenu />
          </Box>
        </Box>
        <Box
          mt={0.5}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box>
            <Typography
              data-testid='page-title'
              fontSize='1.5rem'
              mb={0}
              ml={1}
              fontWeight={300}
            >
              {questionnaireLabel}
            </Typography>
          </Box>
          <Box display='flex' alignItems='center' gap={1}>
            {selectedCaseTypes.map((caseType) => (
              <Chip
                key={caseType.id}
                label={caseType.label}
                colorVariant={ChipVariant.Primary}
              />
            ))}
            {clientName && (
              <Chip
                label={<span>Client: {clientName}</span>}
                icon={<PeopleAltOutlinedIcon />}
                colorVariant={ChipVariant.Transparent}
                sx={{ border: 'none' }}
              />
            )}
            {intakeTime && (
              <Chip
                label={`${formatDateTime(intakeTime)} - ${formatDateTime(
                  closureTime ?? ''
                )}`}
                sx={{
                  color: 'grey.500',
                }}
              />
            )}
            <SettingsMenu />
          </Box>
        </Box>
        {isDiagnosisEnabled && (
          <>
            <Box display='flex' mt={2} mb={1}>
              <NavBar />
            </Box>
            <Divider
              sx={{
                marginTop: '-8px',
              }}
            />
          </>
        )}
      </Container>
      {!isOnline && NetworkMessages.Offline()}
      {wasOffline && isOnline && NetworkMessages.Reconnect()}
    </Box>
  );
};

export default QuestionnaireHeader;
