/* eslint-disable */

import { RootState } from '@/store';
import { configMock } from './configMock';
import { uiMock } from './uiMock';
import { caseMock } from './caseMock';
import { tipsMock } from './tipsMock';
import { questionsMock } from './questionsMock';
import { modulesMock } from './modulesMock';
import { indicatorsMock } from './indicatorsMock';
import { caseTypesMock } from './caseTypesMock';
import { adviceMock } from './adviceMock';
import { displayOrderMock } from './displayOrderMock';
import { editorMock } from './editorMock';
import { cloneDeep } from 'lodash';
import { integrationsMock } from './integrationsMock';
import { questionnaireMock } from './questionnaireMock';
import { adminMock } from './admin/adminMock';

export const initialTestState = {
  config: configMock,
  ui: uiMock,
  case: caseMock,
  tips: tipsMock,
  questions: questionsMock,
  modules: modulesMock,
  indicators: indicatorsMock,
  caseTypes: caseTypesMock,
  advice: adviceMock,
  questionDisplayOrder: displayOrderMock,
  editor: editorMock,
  integrations: integrationsMock,
  questionnaire: questionnaireMock,
  admin: adminMock,
} as unknown as RootState;

export const getInitialTestState = () => cloneDeep(initialTestState);

export default initialTestState;
