import React, { ReactNode } from 'react';
import Head from 'next/head';
import LegacyQuestionnaireHeader from '../../Header/QuestionnaireHeaderLegacy/QuestionnaireHeader';
import { CircularProgress, Container } from '@mui/material';
import InfoBar from '../../InfoBar/InfoBarLegacy';
import { Content, LayoutWrapper } from './styled';
import PrintableSummary from '../../PrintableSummary/PrintableSummary';
import { useAppSelector } from '@/hooks';
import Overlay from '../../Overlay/Overlay';
import EditorModalManager from '../../Editor/EditorModalManager';
import { selectIsEditMode, selectIsRefreshingDisplay } from '@/store/slices/ui';
import ConfirmUseEditorModal from '../../Editor/ConfirmUseEditorModal/ConfirmUseEditorModal';
import { getIsFeatureFlagRoute } from '@/utils/router';
import { useRouter } from 'next/router';
import OverlayCard from '../../Overlay/OverlayCard';
import { Link } from '@s-rm/react-ui-lib';
import useCaseClosure from '@/hooks/useCaseClosure';
import CaseClosureModal from '../../CaseClosureConfirmation/CaseClosureModal';
import ReopenCaseClosureModal from '../../ReopenCaseClosureModal/ReopenCaseClosureModal';
import PrintableCaseClosureDialog from '../../PrintableCaseClosure/PrintableCaseClosureDialog';
import ReopenQuestionnaireModal from '../../ReopenQuestionnaireModal/ReopenQuestionnaireModal';
import QuestionnaireFinishConfirmation from '../../QuestionnaireFinishConfirmation/QuestionnaireFinishConfirmation';

type Props = {
  children?: ReactNode;
  title?: string;
};

const QuestionnaireLayout = ({ children, title = 'IR Intake' }: Props) => {
  const isRefreshingDisplay = useAppSelector(selectIsRefreshingDisplay);
  const isEditMode = useAppSelector(selectIsEditMode);
  const router = useRouter();
  const isFeatureFlagRoute = getIsFeatureFlagRoute(router);

  useCaseClosure({ shouldHandleSelection: true });

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta charSet='utf-8' />
        <meta name='viewport' content='initial-scale=1.0, width=device-width' />
      </Head>

      <LayoutWrapper>
        <header>
          <LegacyQuestionnaireHeader />
        </header>
        <Content>
          <Container sx={{ height: '100%' }} component='main' maxWidth='xl'>
            {children}
          </Container>
        </Content>
        {!isFeatureFlagRoute && (
          <>
            <InfoBar />
            <PrintableSummary />
            {isEditMode && <EditorModalManager />}
            <ConfirmUseEditorModal />
            <PrintableCaseClosureDialog />
          </>
        )}
        <Overlay open={isRefreshingDisplay}>
          <OverlayCard>
            <CircularProgress />
            <h2>Updating data</h2>
            <p>
              If you are not redirected within a few seconds, click{' '}
              <Link href={'/'}>here</Link> .
            </p>
          </OverlayCard>
        </Overlay>
      </LayoutWrapper>
      <>
        <CaseClosureModal />
        <QuestionnaireFinishConfirmation />
        <ReopenCaseClosureModal />
        <ReopenQuestionnaireModal />
      </>
    </>
  );
};

export default QuestionnaireLayout;
