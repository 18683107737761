import React, { ReactNode } from 'react';
import Head from 'next/head';
import { Box } from '@mui/material';
import GenericHeader from '../../Header/GenericHeader/GenericHeader';
import Sidebar from '../../Sidebar/SideBar';
import ContributorsModal from '@/components/admin/ContributorsModal/ContributorsModal';

type Props = {
  children?: ReactNode;
  title?: string;
};

const AdminLayout = ({ children, title = 'Admin Polus' }: Props) => {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta charSet='utf-8' />
        <meta name='viewport' content='initial-scale=1.0, width=device-width' />
      </Head>

      <Box display='flex' sx={{ overflow: 'hidden' }}>
        <Sidebar />
        <Box width='100%' px={4}>
          <GenericHeader />
          <main>{children}</main>
        </Box>
      </Box>
      <ContributorsModal />
    </>
  );
};

export default AdminLayout;
