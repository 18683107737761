import {
  ContributorFunction,
  ContributorStatus,
  OrganisationRole,
  UserRole,
} from '@/store/types/admin';

export const contributorRolesData = {
  [ContributorFunction.TA_SPECIALIST]: {
    label: 'Threat Actor Specialist',
    abreviation: 'TA Specialist',
  },
  [ContributorFunction.DFIR]: {
    label: 'DFIR',
    abreviation: 'DFIR',
  },
  [ContributorFunction.LEGAL]: {
    label: 'Legal',
    abreviation: 'LEGAL',
  },
};

export const contributorStatusData = {
  [ContributorStatus.Active]: {
    label: 'Active',
  },
  [ContributorStatus.Invited]: {
    label: 'Invited',
  },
  [ContributorStatus.Suspended]: {
    label: 'Inactive',
  },
};

export const userRolesOptions = [
  { value: UserRole.User, label: 'User' },
  { value: UserRole.Admin, label: 'Admin' },
];

export const organisationRolesData = {
  [OrganisationRole.Insurer]: {
    label: 'Insurer/Owner',
    value: 'INSURER',
  },
  [OrganisationRole.IncidentManager]: {
    label: 'Incident manager',
    value: 'INCIDENT-MANAGER',
  },
};
