//@ts-ignore
import { GetQuestionnairesForProject } from '@/api/gql/queries.gql';
import client from '../client';
import { RawQuestionnairesForProjectResponse } from '../types/questionnaire';
import { GraphQLError } from 'graphql';
import { QuestionnaireMinimal } from '@/store/types/questionnaire';
import { getFormattedMinimalQuestionnaires } from '../transforms/admin/getFormattedMinimalQuestionnaires';

export const getQuestionnairesForProject = async (
  projectId: string
): Promise<{
  data: QuestionnaireMinimal[];
  errors: readonly GraphQLError[] | undefined;
}> => {
  const {
    data: { getQuestionnairesForProject },
    errors,
  } = (await client.query({
    query: GetQuestionnairesForProject,
    variables: {
      projectId: projectId,
    },
  })) as {
    data: {
      getQuestionnairesForProject: RawQuestionnairesForProjectResponse[];
    };
    errors: readonly GraphQLError[] | undefined;
  };

  return {
    data: getFormattedMinimalQuestionnaires(getQuestionnairesForProject),
    errors,
  };
};

export default getQuestionnairesForProject;
