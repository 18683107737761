import { makeRequest } from '@/api/axios/client';
import { UserRole } from '@/store/types/admin';
import { AxiosError } from 'axios';
import { mockContributorsList } from '@/components/admin/ContributorsTable/utils/mockAPI';

const assignContributorToIncident = async ({
  incidentId,
  userId,
  roles,
}: {
  incidentId: string;
  roles: UserRole[];
  userId: string;
}): Promise<{
  error: { message: string } | null;
  data?: { success: boolean } | null;
}> => {
  // Mock API response
  return {
    data: { success: true },
    error: null,
  };

  try {
    const response = await makeRequest({
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_REST_API_URL}/incident/${incidentId}/assignments`,
      data: {
        userId,
        roles: roles,
      },
    });
    return {
      data: { success: true },
      error: null,
    };
  } catch (error: unknown) {
    return {
      data: null,
      error: {
        message: (error as AxiosError).message,
      },
    };
  }
};

export default assignContributorToIncident;
