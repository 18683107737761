import React, { ReactNode } from 'react';
import { useAppSelector } from '@/hooks';
import {
  selectHasAuthenticated,
  selectHasInitialised,
} from '@/store/slices/config';
import Overlay from '../Overlay/Overlay';
import { getIsAdminRoute, getIsFeatureFlagRoute } from '@/utils/router';
import { useRouter } from 'next/router';
import { useFeatureFlags } from '../FeatureFlags/Provider';
import AuthNotification from './AuthNotification';
import useAnalytics from '@/hooks/useAnalytics';
import AdminLayout from './AdminLayout/AdminLayout';
import QuestionnaireLayoutLegacy from './QuestionnaireLayout/QuestionnaireLayoutLegacy';
import QuestionnaireLayout from './QuestionnaireLayout/QuestionnaireLayout';
import featureFlags from '@/constants/featureFlags';

type Props = {
  children?: ReactNode;
  title?: string;
};

const Layout = ({ children, title = 'IR Intake' }: Props) => {
  const hasInitialised = useAppSelector(selectHasInitialised);
  const hasAuthenticated = useAppSelector(selectHasAuthenticated);
  const router = useRouter();
  const isFeatureFlagRoute = getIsFeatureFlagRoute(router);
  const { hasFetchedFlags, isFeatureFlagOn } = useFeatureFlags();
  const isAuthRoute = router.pathname.includes('auth');
  const isAuthenticating = !hasAuthenticated && !isFeatureFlagRoute;
  const isFetchingFlags =
    !hasFetchedFlags || (!isFeatureFlagRoute && !hasInitialised);
  useAnalytics();
  const isNewLayout = isFeatureFlagOn(
    featureFlags.isUsingNewQuestionnaireLayout
  );

  const isAdminRoute = getIsAdminRoute(router);

  if (isAuthRoute) {
    return <>{children}</>;
  }

  if (isAuthenticating) {
    return (
      <Overlay open>
        <AuthNotification />
      </Overlay>
    );
  }

  if (isFetchingFlags) {
    return <Overlay open={true} />;
  }

  let LayoutComponent = null;

  if (isAdminRoute) {
    LayoutComponent = AdminLayout;
  } else if (isNewLayout) {
    LayoutComponent = QuestionnaireLayout;
  } else {
    LayoutComponent = QuestionnaireLayoutLegacy;
  }

  return <LayoutComponent title={title}>{children}</LayoutComponent>;
};

export default Layout;
