import { defaultFiltersObject, OrganisationRole } from '@/store/types/admin';

export const mockOrganisation1 = {
  id: '1',
  name: 'Hilll and Sons',
  roles: [OrganisationRole.Insurer],
};

export const mockOrganisation2 = {
  id: '2',
  name: 'Wilderman Inc',
  roles: [OrganisationRole.Insurer],
};

export const mockOrganisation3 = {
  id: '3',
  name: 'Oberbrunner-Kassulke',
  roles: [OrganisationRole.IncidentManager],
};

export const mockOrganisation4 = {
  id: '4',
  name: 'Marvin, Durgan and Balistreri',
  roles: [OrganisationRole.IncidentManager],
};

export const mockOrganisation5 = {
  id: '5',
  name: 'Schaefer and Sons',
  roles: [OrganisationRole.Insurer],
};

export const mockOrganisations = {
  entities: {
    [mockOrganisation1.id]: mockOrganisation1,
  },
  ids: [mockOrganisation1.id],
  filters: defaultFiltersObject,
  isLoading: false,
  isUpdating: false,
};
