import { NavigationItem } from '@s-rm/react-ui-lib';

export const sidebarNavigationData: NavigationItem[] = [
  {
    label: 'Incidents',
    path: '/incidents',
  },
  {
    label: 'Users',
    path: '/users',
  },
  {
    label: 'Organisations',
    path: '/organisations',
  },
];
