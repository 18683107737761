// import { createError } from '@/utils/errorHandler';
// import { ErrorCodes } from '@/constants/errors';
// import updateSnackbarNotification from '../updateSnackbar';
// import { SnackbarTypes } from '@s-rm/react-ui-lib';
// import createUser from '@/api/admin/user/createUser';
import { AppThunk } from '../../store';
import { setIsCreatingUser } from '@/store/slices/ui';
import { NewUser } from '@/store/types/admin';

const createUserThunk =
  ({ organisation, email, name, role }: NewUser): AppThunk =>
  async (dispatch) => {
    dispatch(setIsCreatingUser(true));
    // const { data, error } = await createUser({
    //   organisation,
    //   email,
    //   name,
    //   role,
    // });

    // error &&
    //   createError({
    //     code: ErrorCodes.CreateUser,
    //     displaySnackbar: true,
    //   });

    // if (!error) {
    //   dispatch(
    //     updateSnackbarNotification({
    //       message: 'The user has been successfully created!',
    //       type: SnackbarTypes.Success,
    //     })
    //   );
    // }

    dispatch(setIsCreatingUser(false));

    return 'mockUserId';
  };

export default createUserThunk;
