export enum Routes {
  Root = '/',
  CaseType = 'case',
  Specifics = 'specifics',
  Summary = 'summary',
  Auth = 'auth',
  Error = 'error',
  IncidentDescription = 'incident-description',
  FeatureFlags = 'feature-flags',
  Closure = 'closure',
  Questionnaire = 'questionnaire',
  Questionnaires = 'questionnaires',
  Incidents = 'incidents',
  Incident = 'incident',
  Users = 'users',
  Organisations = 'organisations',
}

export interface RouteObject {
  label: string;
  path: Routes;
  requiresAuth?: boolean;
  requiresCaseId?: boolean;
  requiresQuestionnaireId?: boolean;
  isAdminRoute?: boolean;
}

export interface RouteData {
  [Routes.Root]: RouteObject;
  [Routes.CaseType]: RouteObject;
  [Routes.Specifics]: RouteObject;
  [Routes.Summary]: RouteObject;
  [Routes.Closure]: RouteObject;
  [Routes.Auth]: RouteObject;
  [Routes.Error]: RouteObject;
  [Routes.IncidentDescription]: RouteObject;
  [Routes.FeatureFlags]: RouteObject;
  [Routes.Questionnaire]: RouteObject;
  [Routes.Questionnaires]: RouteObject;
  [Routes.Incidents]: RouteObject;
  [Routes.Incident]: RouteObject;
  [Routes.Users]: RouteObject;
  [Routes.Organisations]: RouteObject;
}

export const routeData: RouteData = {
  [Routes.Root]: {
    label: '/',
    path: Routes.Root,
    requiresAuth: true,
    requiresCaseId: true,
  },
  [Routes.CaseType]: {
    label: 'Case Type',
    path: Routes.CaseType,
    requiresCaseId: true,
    requiresAuth: true,
  },
  [Routes.Specifics]: {
    label: 'Specifics',
    path: Routes.Specifics,
    requiresCaseId: true,
    requiresAuth: true,
  },
  [Routes.Summary]: {
    label: 'Summary',
    path: Routes.Summary,
    requiresCaseId: true,
    requiresAuth: true,
  },
  [Routes.Closure]: {
    label: 'Closure',
    path: Routes.Closure,
    requiresCaseId: true,
    requiresAuth: true,
  },
  [Routes.IncidentDescription]: {
    label: 'IncidentDescription',
    path: Routes.IncidentDescription,
    requiresCaseId: true,
    requiresAuth: true,
  },
  [Routes.FeatureFlags]: {
    label: 'Feature Flags',
    path: Routes.FeatureFlags,
    requiresAuth: true,
  },
  [Routes.Auth]: {
    label: 'Auth',
    path: Routes.Auth,
  },
  [Routes.Error]: {
    label: 'Error',
    path: Routes.Error,
  },
  [Routes.Questionnaire]: {
    label: 'Questionnaire',
    path: Routes.Questionnaire,
    requiresQuestionnaireId: true,
    requiresAuth: true,
  },
  [Routes.Questionnaires]: {
    label: 'Questionnaires',
    path: Routes.Questionnaires,
    requiresQuestionnaireId: true,
    requiresAuth: true,
  },
  [Routes.Incidents]: {
    label: 'Incidents',
    path: Routes.Incidents,
    requiresAuth: true,
    isAdminRoute: true,
  },
  [Routes.Incident]: {
    label: 'Incident',
    path: Routes.Incident,
    requiresAuth: true,
    isAdminRoute: true,
  },
  [Routes.Users]: {
    label: 'Users',
    path: Routes.Users,
    requiresAuth: true,
    isAdminRoute: true,
  },
  [Routes.Organisations]: {
    label: 'Organisations',
    path: Routes.Organisations,
    requiresAuth: true,
    isAdminRoute: true,
  },
};

export const navBarRoutesLegacy: RouteObject[] = [
  routeData[Routes.CaseType],
  routeData[Routes.Specifics],
  routeData[Routes.Summary],
  routeData[Routes.Closure],
];

export const navBarRoutes: RouteObject[] = [
  routeData[Routes.CaseType],
  routeData[Routes.Specifics],
  routeData[Routes.Summary],
];
