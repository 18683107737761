import getQuestionnairesForProject from '@/api/queries/getQuestionnairesForProject';
import { useAppSelector } from '@/hooks';
import {
  selectCurrentQuestionnaire,
  selectProjectId,
  selectQuestionnaireId,
  selectQuestionnaireLabel,
} from '@/store/slices/questionnaire';
import { getIsClosure, getIsIntake } from '@/utils/helpers';
import { ButtonMenu } from '@s-rm/react-ui-lib';
import { useEffect, useState } from 'react';

const getQuestionnaireTypeName = (label: string) => {
  const isIntake = getIsIntake(label);
  const isClosure = getIsClosure(label);

  let questionnaireName = label;
  if (isIntake) {
    questionnaireName = 'Intake';
  }
  if (isClosure) {
    questionnaireName = 'Closure';
  }
  return questionnaireName;
};

const QuestionnaireSelector = () => {
  const {
    projectId,
    questionnaireId: currentQuestionnaireId,
    label: currentQuestionnaireLabel,
    project: { name: projectName },
  } = useAppSelector(selectCurrentQuestionnaire);
  const [questionnaireOptions, setQuestionnaireOptions] = useState([
    {
      label: `Project: ${projectName} - ${getQuestionnaireTypeName(
        currentQuestionnaireLabel
      )}`,
      value: currentQuestionnaireId,
      onClick: () => {},
    },
  ]);

  const fetchQuestionnaires = async () => {
    const { data } = await getQuestionnairesForProject(projectId);

    const options = data.map((questionnaire) => {
      return {
        label: `Project: ${
          questionnaire.projectName
        } - ${getQuestionnaireTypeName(questionnaire.label)}`,
        value: questionnaire.id,
        onClick: () =>
          (window.location.href = `/questionnaire/${questionnaire.id}`),
      };
    });

    setQuestionnaireOptions(options);
  };

  useEffect(() => {
    fetchQuestionnaires();
  }, []);
  return (
    <ButtonMenu
      value={currentQuestionnaireId}
      options={questionnaireOptions}
      buttonType='chip'
    />
  );
};

export default QuestionnaireSelector;
