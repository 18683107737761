import { User } from '@/api/types/user';
import { ErrorCodes } from '@/constants/errors';

export interface AppError {
  error?: Error;
  code: ErrorCodes;
}

export enum AccessPermissions {
  ViewIncident = 'VIEW_INCIDENT',
  ViewIncidents = 'VIEW_INCIDENTS',
  ViewContributors = 'VIEW_CONTRIBUTORS',
  ViewOrganisations = 'VIEW_ORGANISATIONS',
  ViewQuestionnaires = 'VIEW_QUESTIONNAIRES',
  ViewUserDetail = 'VIEW_USER_DETAIL',
  ViewClosure = 'VIEW_CLOSURE',
  UpdateInsurerPrimaryContact = 'UPDATE_INSURER_PRIMARY_CONTACT',
  ViewUsersInCurrentOrg = 'VIEW_USERS_IN_CURRENT_ORG',
  ViewIncidentsPerUser = 'VIEW_INCIDENTS_PER_USER',
  ViewIncidentManagerOrgs = 'VIEW_INCIDENT_MANAGER_ORGS',
  UpdateIncidentManagerOrg = 'UPDATE_INCIDENT_MANAGER_ORG',
  UpdateIncidentManagerPrimaryContact = 'UPDATE_INCIDENT_MANAGER_PRIMARY_CONTACT',
  ViewIncidentByContributor = 'VIEW_INCIDENT_BY_CONTRIBUTOR',
  CreateIncident = 'CREATE_INCIDENT',
  ViewClosedClosure = 'VIEW_CLOSED_CLOSURE',
  ViewClosedIncident = 'VIEW_CLOSED_INCIDENT',
  ViewEditor = 'VIEW_EDITOR',
  CanSubmitClosure = 'CAN_SUBMIT_CLOSURE',
}

export interface ConfigSlice {
  hasInitialised: boolean;
  hasAuthenticated: boolean;
  isCaseValid: boolean;
  errors: AppError[];
  user: User | {};
  featureFlags: Record<string, boolean>;
  accessPermissions: AccessPermissions[];
}
