import { Question } from '@/store/types/question';
import { Tip } from '@/store/types/tip';
import { CaseType, ProccessStep } from '@/store/types/caseType';
import { Indicator } from '@/store/types/indicator';
import { Module, ModuleGroup } from '@/store/types/module';
import { Advice } from '@/store/types/advice';
import formatAnswers from '../utils/formatAnswers';
import {
  FormattedCase,
  FormattedDescription,
  FormattedQuestionnaire,
} from '@/api/types/initialData';
import { convertNewLinesToBreakTags, getuuid } from '@/utils/helpers';
import { EntityType } from '@/store/types/basicTypes';
import { User } from '@/api/types/user';
import { Integration } from '@/store/types/integrations';
import { getFeatureFlagFromStorage } from '@/components/FeatureFlags/utils/checkAndSetFeatureFlag';

export const getFormattedCase = (caseData: any): FormattedCase => ({
  caseId: caseData.projectId || '',
  selectedCaseTypes: caseData.selectedCaseTypeIds || [],
  selectedIndicators: caseData.selectedIndicatorIds || [],
  dismissedIndicators: caseData.dismissedIndicatorIds || [],
  manuallySelectedModules: caseData.selectedModules?.map(
    (m: any) => m.moduleId
  ),
  questionValues: caseData.answers ? formatAnswers(caseData.answers) : {},
  client: {
    name: caseData.client.name || '',
  },
  project: {
    name: caseData.project.name || '',
  },
  isActive: caseData.active, // This field currently is used to control the closure active state.
  isIntakeActive: caseData.isIntakeActive,
  startedAt: caseData.intakeTime,
  completedAt: caseData.closureTime,
  triggeredIntegrations: caseData.triggeredIntegrations || [],
});

export const getFormattedQuestionnaire = (
  questionnaireData: any
): FormattedQuestionnaire => {
  const {
    id: questionnaireId,
    projectId,
    config,
    answers,
    client: { name: clientName = '' } = {},
    project: { name: projectName = '' } = {},
    active: isActive = false,
    startedAt = null,
    completedAt = null,
    description: notes = '', // Note: This field will change questionnaire.description -> questionnaire.notes
    triggeredIntegrations = [],
  } = questionnaireData;
  return {
    questionnaireId,
    projectId,
    selectedCaseTypes: config.features.diagnosis.selectedCaseTypeIds ?? [],
    selectedIndicators: config.features.diagnosis.selectedIndicatorIds ?? [],
    dismissedIndicators: config.features.diagnosis.dismissedIndicatorIds ?? [],
    manuallySelectedModules:
      config.features.addModules.manuallySelectedModules ?? [],
    questionValues: answers ? formatAnswers(answers) : {},
    client: {
      name: clientName,
    },
    project: {
      name: projectName,
    },
    isDiagnosisEnabled: getFeatureFlagFromStorage('IS_USING_STATIC_MODULES')
      ? false
      : config.features.diagnosis.toggled,
    isAddModulesEnabled: config.features.addModules.toggled,
    isNotesEnabled: config.features.addNotes.toggled,
    staticModuleIds: [
      ...(getFeatureFlagFromStorage('IS_USING_STATIC_MODULES')
        ? // Example case closure modules when static modules are activated
          [
            '5cbdffff-6ed6-4bc8-b291-6d75f9af2f5d',
            '46a81998-b35e-44b2-aab7-dba1cef96683',
            'ee4e4839-840a-4b42-8035-2b062be9902e',
            '23cb4287-ac67-4b4b-bd2c-68ce48d13c64',
            'bb082c73-50ea-4e8c-8dba-bea479109cc8',
            'd678b102-22a3-4509-8020-a66b9ac053b3',
            'c525734b-c759-46e8-ae8c-41fd720c745b',
            '0f4f4815-2d88-42d3-ad11-05afdffe5633',
            '4fbbdf55-ddab-46de-9587-52b3fb34703f',
            '1abbb9a2-e662-49da-abe6-d1ef5226e4b7',
            '7527ad43-13c0-4e13-83a4-1d70a52618c5',
            '5bcb05a0-06ba-433d-82ff-bc151b19f150',
          ]
        : config.staticModuleIds || []), // Default to the staticModuleIds recieved from the questionnaire API.
    ],
    isActive,
    startedAt,
    completedAt,
    triggeredIntegrations,
    notes,
    label: config.label,
  };
};

export const getFormattedDescription = (
  description: any
): FormattedDescription =>
  description
    ? {
        text: description.text,
        hasSuggestedIndicators: description.hasSuggestedIndicators,
        suggestedIndicators:
          description.suggestedIndicators?.indicatorIds || [],
      }
    : {
        text: '',
        hasSuggestedIndicators: false,
        suggestedIndicators: [],
      };

export const getFormattedQuestion = (question: any): Question => ({
  id: question.id,
  entityType: EntityType.Question,
  control: question.control,
  placeholder: question.placeholder,
  guidance: question.guidance,
  label: convertNewLinesToBreakTags(question.label),
  triggers: question.triggers.map((trigger: any) => ({
    componentType: trigger.componentType,
    componentId: trigger.componentId,
    action: trigger.action,
    value: trigger.triggeringValue,
    id: getuuid(),
  })),
  options: question.options.map((option: any) => ({
    label: option.label,
    value: option.value,
    id: getuuid(),
  })),
  validation: {
    pattern: question.validation.pattern,
    isRequired: question.validation.required,
    maxLength: question.validation.maxLength,
    minLength: question.validation.minLength,
    maxValue: question.validation.maxValue,
    minValue: question.validation.minValue,
    longText: question.validation.longText,
  },
  version: question.version,
  isSessionOnly: question.isSessionOnly || false,
  integrationTags: question.integrationTags || [],
});

export const getFormattedTip = (tip: any): Tip => ({
  id: tip.id,
  label: tip.label,
  text: convertNewLinesToBreakTags(tip.text),
  advice: tip.linkedAdviceIds,
  entityType: EntityType.Tip,
  isSessionOnly: tip.isSessionOnly || false,
});

export const getFormattedCaseType = (caseType: any): CaseType => ({
  id: caseType.id,
  label: convertNewLinesToBreakTags(caseType.label),
  indicators: caseType.indicatorIds,
  modules: caseType.moduleIds,
  triggeredModules: [],
  isSessionOnly: caseType.isSessionOnly || false,
  entityType: EntityType.CaseType,
  processStep: caseType.processStep || ProccessStep.Intake,
});

export const getFormattedIndicator = (indicator: any): Indicator => ({
  id: indicator.id,
  label: indicator.label,
  isSessionOnly: indicator.isSessionOnly || false,
});

export const getFormattedModule = (module: any): Module => ({
  id: module.id,
  label: module.label,
  description: convertNewLinesToBreakTags(module.description),
  questions: module.rootQuestionIds,
  entityType: EntityType.Module,
  isSessionOnly: module.isSessionOnly || false,
  roles: module.role || [],
});

export const getFormattedModuleGroup = (group: any): ModuleGroup => ({
  id: group.id,
  label: group.label,
  moduleIds: group.moduleIds,
  priorityIndex: group.priorityIndex,
  isSessionOnly: group.isSessionOnly || false,
});

export const getFormattedAdvice = (advice: any): Advice => ({
  id: advice.id,
  label: advice.label,
  text: convertNewLinesToBreakTags(advice.text),
  entityType: EntityType.Advice,
  isSessionOnly: advice.isSessionOnly || false,
});

export const getFormattedUser = (user: any): User => ({
  fullName: user?.full_name || user?.fullName,
  firstName: user?.first_name || user?.firstName,
  lastName: user?.last_name || user?.lastName,
  emailAddress: user?.email_address || user?.emailAddress,
  telephone: user?.telephone,
});

export const getFormattedIntegrations = (integrations: any): Integration => ({
  name: integrations?.name,
  id: integrations?.id,
  description: integrations?.description,
});
