import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { initialState } from '@/store/initialState';

import incidentsReducers from './incidents';
import contributorsReducers from './contributors';
import questionnairesReducers from './questionnaires';
import organisationsReducers from './organisations';
import usersReducer from './users';

export const adminSlice = createSlice({
  name: 'admin',
  initialState: initialState.admin,
  reducers: {
    ...questionnairesReducers,
    ...incidentsReducers,
    ...contributorsReducers,
    ...organisationsReducers,
    ...usersReducer,
  },
});

export const {
  setQuestionnaires,
  setIncidents,
  setIncidentsSearchTerm,
  setIsLoadingIncidents,
  setIncidentsFilters,
  updateIncidentsFilters,
  resetIncidentsFilters,
  setContributors,
  updateContributorFilters,
  resetContributorsFilters,
  setIsLoadingContributors,
  updateIncident,
  setOrganisations,
  updateOrganisationFilters,
  resetOrganisationsFilters,
  setIsLoadingOrganisations,
  setUsers,
  updateUsersFilters,
  setUsersFilters,
  setUsersSearchTerm,
  setIsLoadingUsers,
  resetUsersFilters,
  updateUser,
  setIsUpdatingOrganisation,
} = adminSlice.actions;

// Questionnaire selectors
export const selectQuestionnaireEntities = (state: RootState) =>
  state.admin.questionnaires.entities;
export const selectQuestionnaireEntitiesAsArray = (state: RootState) =>
  state.admin.questionnaires.ids.map(
    (id) => state.admin.questionnaires.entities[id]
  );
export const selectQuestionnaireIds = (state: RootState) =>
  state.admin.questionnaires.ids;

// Incident selectors
export const selectIncidentEntityById = (state: RootState, id: string) =>
  state.admin.incidents.entities[id];

// Selector to find an incident by insurerId (which is the "insurer name")
export const selectIncidentByInsurerId = (
  state: RootState,
  insurerId: string
) =>
  Object.values(state.admin.incidents.entities).find(
    (incident) => incident?.incidentOwnerOrganizationName === insurerId
  );

export const selectIncidentsEntities = (state: RootState) =>
  state.admin.incidents.entities;

export const selectIncidentsAsArray = (state: RootState) =>
  state.admin.incidents.ids.map((id) => state.admin.incidents.entities[id]);

export const selectIncidentsFilters = (state: RootState) =>
  state.admin.incidents.filters;

export const selectIsLoadingIncidents = (state: RootState) =>
  state.admin.incidents.isLoading;

// Contributor selectors
export const selectContributorEntities = (state: RootState) =>
  state.admin.contributors.entities;
export const selectContributorsEntitiesAsArray = (state: RootState) =>
  state.admin.contributors.ids.map(
    (id) => state.admin.contributors.entities[id]
  );
export const selectContributorIds = (state: RootState) =>
  state.admin.contributors.ids;
export const selectContributorsFilters = (state: RootState) =>
  state.admin.contributors.filters;
export const selectIsLoadingContributors = (state: RootState) =>
  state.admin.contributors.isLoading;

// Organisations selectors
export const selectOrganisationEntities = (state: RootState) =>
  state.admin.organisations.entities;
export const selectOrganisationsEntitiesAsArray = (state: RootState) =>
  state.admin.organisations.ids.map(
    (id) => state.admin.organisations.entities[id]
  );
export const selectOrganisationIds = (state: RootState) =>
  state.admin.organisations.ids;
export const selectOrganisationsFilters = (state: RootState) =>
  state.admin.organisations.filters;

export const selectIsLoadingOrganisations = (state: RootState) =>
  state.admin.organisations.isLoading;

export const selectOrganisationById = (state: RootState, id: string) =>
  state.admin.organisations.entities[id] || {};

// Users selectors
export const selectUserEntities = (state: RootState) =>
  state.admin.users.entities;
export const selectUsersEntitiesAsArray = (state: RootState) =>
  state.admin.users.ids.map((id) => state.admin.users.entities[id]);
export const selectUserIds = (state: RootState) => state.admin.users.ids;
export const selectUsersFilters = (state: RootState) =>
  state.admin.users.filters;

export const selectIsLoadingUsers = (state: RootState) =>
  state.admin.users.isLoading;

export const selectUserEntityById = (state: RootState, id: string) =>
  state.admin.users.entities[id];

export default adminSlice.reducer;
