import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '@/store/initialState';
import { TransformedInitialData } from '@/api/types/initialData';
import { RootState } from '../store';
import { CaseTypeId } from '../types/basicTypes';
import { CaseType } from '../types/caseType';

export const caseTypesSlice = createSlice({
  name: 'caseTypes',
  initialState: initialState.caseTypes,
  reducers: {
    setCaseTypes: (
      state,
      action: PayloadAction<TransformedInitialData['caseTypes']>
    ) => {
      state.entities = action.payload.entities;
      state.ids = action.payload.ids;
    },
    addCaseType: (state, action: PayloadAction<CaseType>) => {
      state.entities[action.payload.id] = action.payload;
      state.ids.push(action.payload.id);
    },
    removeCaseType: (state, action: PayloadAction<{ targetId: string }>) => {
      const keyToRemove = action.payload.targetId;
      const { [keyToRemove]: removedEntity, ...remainingEntities } =
        state.entities;

      state.entities = remainingEntities;
      state.ids = state.ids.filter((id) => id !== keyToRemove);
    },
    addSelectedCaseTypes: (state, action: PayloadAction<string[]>) => {
      state.selected = Array.from(
        new Set([...state.selected, ...action.payload])
      );
      state.suggested = Array.from(
        new Set([...state.suggested, ...action.payload])
      );
    },
    addSuggestedCaseTypes: (state, action: PayloadAction<string[]>) => {
      state.suggested = Array.from(
        new Set([...state.suggested, ...action.payload])
      );
    },
    removeSuggestedCaseTypes: (state, action: PayloadAction<string[]>) => {
      state.suggested = state.suggested.filter(
        (id) => !action.payload.includes(id)
      );
    },
    removeSelectedCaseTypes: (state, action: PayloadAction<string[]>) => {
      state.selected = state.selected.filter(
        (id) => !action.payload.includes(id)
      );
    },
    updateCaseType: (
      state,
      action: PayloadAction<{
        id: CaseTypeId;
        updates: Partial<CaseType>;
      }>
    ) => {
      state.entities[action.payload.id] = {
        ...state.entities[action.payload.id],
        ...action.payload.updates,
      };
    },
    setIsLoadingCaseTypes: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setCaseTypes,
  addSelectedCaseTypes,
  removeSelectedCaseTypes,
  addSuggestedCaseTypes,
  removeSuggestedCaseTypes,
  updateCaseType,
  addCaseType,
  removeCaseType,
  setIsLoadingCaseTypes,
} = caseTypesSlice.actions;

export const selectSelectedCaseTypes = (state: RootState) =>
  state.caseTypes.selected.map((id) => state.caseTypes.entities[id]);
export const selectCaseTypeEntities = (state: RootState) =>
  state.caseTypes.entities;
export const selectSelectedCaseTypeIds = (state: RootState) =>
  state.caseTypes.selected;
export const selectAllCaseTypesAsArray = (state: RootState) =>
  state.caseTypes.ids.map((id) => state.caseTypes.entities[id]);
export const selectCaseType = (state: RootState, id: string) =>
  state.caseTypes.entities[id];
export const selectSuggestedCaseTypes = (state: RootState) =>
  state.caseTypes.suggested;
export const selectIndicatorsByCaseType = (
  state: RootState,
  caseTypeId: CaseTypeId
) => state.caseTypes.entities[caseTypeId].indicators;
export const selectIsLoadingCaseTypes = (state: RootState) =>
  state.caseTypes.isLoading;

export default caseTypesSlice.reducer;
