import { Alert, AlertTitle, Typography } from '@mui/material';
import ConfirmationModal from '../ConfirmationModal';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectIsConfirmingFinishQuestionnaire,
  setIsConfirmingFinishQuestionnaire,
} from '@/store/slices/ui';
import submitQuestionnaire from '@/store/thunks/submitQuestionnaireThunk';
import updateSnackbarNotification from '@/store/thunks/updateSnackbar';
import { SnackbarTypes } from '@/store/types/ui';
import { getFeatureFlagFromStorage } from '../FeatureFlags/utils/checkAndSetFeatureFlag';

const QuestionnaireFinishConfirmation = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectIsConfirmingFinishQuestionnaire);
  const IS_USING_QUESTIONNAIRE_API = getFeatureFlagFromStorage(
    'IS_USING_QUESTIONNAIRE_API'
  );
  const title = IS_USING_QUESTIONNAIRE_API ? 'questionnaire' : 'intake';

  const handleClose = () => dispatch(setIsConfirmingFinishQuestionnaire(false));

  const handleConfirm = () => {
    dispatch(setIsConfirmingFinishQuestionnaire(false));
    dispatch(submitQuestionnaire());
    dispatch(
      updateSnackbarNotification({
        message: `The ${title} has been submitted successfully`,
        type: SnackbarTypes.Success,
      })
    );
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title={`Submit ${title}`.toUpperCase()}
      confirmText='Yes, continue'
      onConfirm={handleConfirm}
      onClose={handleClose}
      testId='intake-finish-confirmation-modal'
    >
      <Alert severity='warning' data-testid='intake-finish-confirm-warning'>
        <AlertTitle>Warning</AlertTitle>
        <Typography mb={2}>
          Are you sure you want to submit this {title}?
        </Typography>
      </Alert>
    </ConfirmationModal>
  );
};

export default QuestionnaireFinishConfirmation;
