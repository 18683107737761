export const questionnaireMock = {
  id: 'eb599004-ee67-4d5c-90b2-bba6350e3e6f',
  createdAt: '2024-07-18T11:23:45.205Z',
  updatedAt: '2024-07-18T11:25:45.205Z',
  projectId: '63defc37-1c95-4265-91d6-8a08e8a56b11',
  project: {
    name: 'Project Delta',
    __typename: 'Project',
  },
  clientId: '923cda5a-5426-41a7-b259-901e785dece5',
  client: {
    name: 'Goldman Sachs Gr',
    __typename: 'Client',
  },
  active: true,
  startedAt: '2024-07-18T11:25:45.205Z',
  completedAt: null,
  questionnaireTypeId: 'c3f0dc51-8fea-4ebe-91ef-19f3001afa1a',
  config: {
    id: 'eb599004-ee67-4d5c-90b2-bba6350e3e6f',
    label: 'Configuration label',
    staticModuleIds: [],
    features: {
      diagnosis: {
        selectedIndicatorIds: [],
        dismissedIndicatorIds: [],
        selectedCaseTypeIds: [],
        toggled: false,
      },
      addModules: {
        toggled: false,
        manuallySelectedModules: [],
      },
      addNotes: {
        toggled: false,
        note: 'This is the add notes feature description.',
      },
    },
  },
  answers: [],
  description: 'This is the questionnaire description',
  triggeredIntegrations: ['Jira', 'S1'],
};
