import { store } from '@/store';
// @ts-ignore
import { AddQuestionnaireDescription } from '@/api/gql/mutations.gql';
import client from '../client';
import { createError } from '@/utils/errorHandler';
import { ErrorCodes } from '@/constants/errors';

const updateDescription = async () => {
  const text = store.getState().questionnaire.description;
  const questionnaireId = store.getState().questionnaire.questionnaireId;

  try {
    const { data, errors } = await client.mutate({
      mutation: AddQuestionnaireDescription,
      variables: {
        questionnaireId,
        text,
      },
    });

    if (errors) {
      createError({
        code: ErrorCodes.AddDescription,
        error: new Error(JSON.stringify(errors)),
        displaySnackbar: true,
      });
    }

    return { data, errors };
  } catch (error) {
    createError({
      code: ErrorCodes.AddDescription,
      displaySnackbar: true,
    });
  }
};

export default updateDescription;
