import { AccessPermissions } from '@/store/types/config';
import { useAppSelector } from './storeHooks';
import { selectAccessPermissions } from '@/store/slices/config';

const useHasAuthorization = ({
  requiredPermissions,
  requiredConditions,
  preCheckConditions,
}: {
  requiredPermissions: AccessPermissions[];
  requiredConditions?: boolean[];
  preCheckConditions?: boolean[];
}) => {
  const existingPermissions = useAppSelector(selectAccessPermissions) ?? [];

  const shouldCheckPermissions =
    preCheckConditions?.every((condition) => condition) ?? true;
  if (!shouldCheckPermissions) {
    return true; // If pre-check conditions fail, skip further checks
  }

  // Validate required permissions
  const permissionsAccepted = requiredPermissions.every((permission) =>
    existingPermissions.includes(permission)
  );
  if (!permissionsAccepted) {
    return false;
  }

  // Check required conditions if provided
  return requiredConditions?.every((condition) => condition) ?? true;
};

export default useHasAuthorization;
