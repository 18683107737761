import { PayloadAction } from '@reduxjs/toolkit';

import {
  AdminSlice,
  defaultFiltersObject,
  Filters,
  Incident,
} from '@/store/types/admin';
import {
  UpdateFilterPayload,
  updateFilters,
} from '@/store/utils/updateFilters';

const incidentsReducers = {
  setIncidents: (state: AdminSlice, action: PayloadAction<Incident[]>) => {
    state.incidents.entities = action.payload.reduce((acc, incident) => {
      acc[incident.id] = incident;
      return acc;
    }, {} as Record<string, Incident>);
    state.incidents.ids = action.payload.map((v) => v.id);
  },
  updateIncidentsFilters: (
    state: AdminSlice,
    action: PayloadAction<UpdateFilterPayload>
  ) => {
    state.incidents.filters = updateFilters(
      state.incidents.filters,
      action.payload
    );
  },
  setIncidentsFilters: (
    state: AdminSlice,
    action: PayloadAction<Partial<Filters>>
  ) => {
    state.incidents.filters = Object.keys(action.payload).reduce(
      (acc, key) => ({
        ...acc,
        [key]: action.payload[key as keyof Filters],
      }),
      state.incidents.filters
    );
  },
  setIncidentsSearchTerm: (
    state: AdminSlice,
    action: PayloadAction<string>
  ) => {
    state.incidents.filters = {
      ...state.incidents.filters,
      searches: [
        {
          column: 'title',
          value: action.payload,
        },
      ],
    };
  },
  setIsLoadingIncidents: (
    state: AdminSlice,
    action: PayloadAction<boolean>
  ) => {
    state.incidents.isLoading = action.payload;
  },
  resetIncidentsFilters: (state: AdminSlice) => {
    state.incidents.filters = defaultFiltersObject;
  },
  updateIncident: (
    state: AdminSlice,
    action: PayloadAction<{ id: string; updates: Incident }>
  ) => {
    const { id, updates } = action.payload;
    state.incidents.entities[id] = {
      ...state.incidents.entities[id],
      ...updates,
    };
  },
};

export default incidentsReducers;
