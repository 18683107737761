import { useAppDispatch, useAppSelector } from '@/hooks';
import { Box, Container } from '@mui/material';
import { FlexContainer } from './styled';
import NavButton from './NavButton';
import {
  selectIsQuestionnaireActive,
  selectQuestionnaireLabel,
} from '@/store/slices/questionnaire';
import {
  selectIsEditMode,
  setIsClosureSummaryDialogOpen,
  setIsConfirmingCaseClosure,
  setIsConfirmingFinishQuestionnaire,
} from '@/store/slices/ui';
import EditModeContent from './EditModeContent';
import GlobalQuestionCounter from '../RequiredQuestionsCounter/GlobalQuestionCounter';
import { Button } from '@s-rm/react-ui-lib';
import { getIsClosure } from '@/utils/helpers';
import CaseClosureModal from '../CaseClosureConfirmation/CaseClosureModal';
import useHasAuthorization from '@/hooks/useHasAuthorization';
import { AccessPermissions } from '@/store/types/config';
import { getFeatureFlagFromStorage } from '../FeatureFlags/utils/checkAndSetFeatureFlag';

const InfoBar = () => {
  const dispatch = useAppDispatch();

  const isEditMode = useAppSelector(selectIsEditMode);
  const isQuestionnaireActive = useAppSelector(selectIsQuestionnaireActive);
  const questionnaireLabel = useAppSelector(selectQuestionnaireLabel);
  const isClosure = getIsClosure(questionnaireLabel);

  const canSubmitClosure = useHasAuthorization({
    requiredPermissions: [AccessPermissions.CanSubmitClosure],
  });

  const IS_USING_QUESTIONNAIRE_API = getFeatureFlagFromStorage(
    'IS_USING_QUESTIONNAIRE_API'
  );

  const handleClickSubmitCase = () => {
    IS_USING_QUESTIONNAIRE_API
      ? dispatch(setIsConfirmingFinishQuestionnaire(true))
      : dispatch(setIsConfirmingCaseClosure(true));
  };

  const handleClickClosurePreview = () =>
    dispatch(setIsClosureSummaryDialogOpen(true));

  return (
    <>
      <Box py={5} data-testid='info-bar'>
        <Container maxWidth='xl'>
          <FlexContainer>
            {!!isEditMode && <EditModeContent />}
            {!isEditMode && (
              <>
                <Box ml='auto' mr={2}>
                  <GlobalQuestionCounter />
                </Box>
                {isClosure ? (
                  <Box display='flex' gap={2}>
                    <Button
                      variant='outlined'
                      onClick={handleClickClosurePreview}
                      data-testid='infobarpreview-btn'
                    >
                      Preview
                    </Button>
                    {canSubmitClosure && (
                      <Button
                        disabled={!isQuestionnaireActive}
                        variant='contained'
                        onClick={handleClickSubmitCase}
                        data-testid={
                          isQuestionnaireActive
                            ? 'infobar-close-case-btn'
                            : 'infobar-case-closed-btn'
                        }
                      >
                        {isQuestionnaireActive
                          ? 'Submit Closure'
                          : 'Closure Submitted'}
                      </Button>
                    )}
                  </Box>
                ) : (
                  <NavButton />
                )}
              </>
            )}
          </FlexContainer>
        </Container>
      </Box>
      <CaseClosureModal />
    </>
  );
};

export default InfoBar;
