import { useState, useEffect } from 'react';
import { MultiSelectSearch } from '@s-rm/react-ui-lib';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectIsAddContributorModalOpen,
  setIsAddContributorModalOpen,
  setIsOpenCreateUserFormDialog,
} from '@/store/slices/ui';
import { useTheme } from '@mui/material';
import fetchContributors from '@/api/admin/contributors/fetchContributors';
import {
  Contributor,
  defaultFiltersObject,
  UserRole,
} from '@/store/types/admin';
import { useRouter } from 'next/router';
import assignContributorToIncident from '@/api/admin/contributors/assignContributorToIncident';
import { contributorRolesData } from '@/data/admin';
import { CreateUserFormDialog } from '../CreateUserFormDialog/CreateUserFormDialog';

export const AddContributorDialog = ({
  onAddContributor,
}: {
  onAddContributor: () => void;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectIsAddContributorModalOpen);
  const router = useRouter();
  const [isLoadingContributors, setIsLoadingContributors] = useState(false);
  const [contributors, setContributors] = useState<Contributor[]>([]);
  const [selectedContributor, setSelectedContributor] = useState<string>('');
  const incidentId = router.query.id as string;
  const contributorsOptions =
    contributors?.map((contributor) => ({
      value: contributor.id,
      label: contributor.name,
      secondaryLabel: contributor.email,
    })) || [];

  const handleOnClickCancel = () => {
    dispatch(setIsAddContributorModalOpen(false));
  };

  const handleOnClickCreate = async () => {
    const roles =
      contributors.find((c) => c.id === selectedContributor)?.roles || [];

    await assignContributorToIncident({
      incidentId,
      userId: selectedContributor,
      roles: roles.map(
        (role) => contributorRolesData[role].abreviation
      ) as unknown as UserRole[],
    });

    dispatch(setIsAddContributorModalOpen(false));
    onAddContributor();
  };

  const handleClickAddNewContributor = () => {
    dispatch(setIsOpenCreateUserFormDialog(true));
  };

  const handleCreateNewUser = (userInfo: {
    id: string;
    name: string;
    email: string;
  }) => {
    setContributors((prevContributors) => [
      {
        id: userInfo.id,
        name: userInfo.name,
        email: userInfo.email,
        roles: [],
      } as unknown as Contributor,
      ...prevContributors,
    ]);

    setSelectedContributor(userInfo.id);
  };

  const getContributors = async () => {
    setIsLoadingContributors(true);

    const { data } = await fetchContributors({
      incidentId,
      filters: defaultFiltersObject,
    });

    setIsLoadingContributors(false);
    setContributors(data);
  };

  useEffect(() => {
    getContributors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CreateUserFormDialog
      role='contributor'
      onCreate={handleCreateNewUser}
      isDialogOpen={isOpen}
      onCancel={handleOnClickCancel}
    />
  );
};
