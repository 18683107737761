import { makeRequest } from '@/api/axios/client';
import { Contributor, Filters } from '@/store/types/admin';
import {
  IncidentsResponse,
  emptyPagination,
  PaginationRequest,
  PaginationResponse,
} from '../types';
import { AxiosError } from 'axios';
import { mockContributorsList } from '@/components/admin/ContributorsTable/utils/mockAPI';

const fetchContributors = async ({
  filters,
  incidentId,
  searchTerm: queryString,
}: {
  filters: Filters;
  incidentId: string;
  searchTerm?: string;
}): Promise<{
  data: Contributor[];
  pagination: PaginationResponse;
  error?: { message: string };
}> => {
  // Mock API response
  return {
    data: mockContributorsList,
    pagination: emptyPagination,
  };

  try {
    const response = await makeRequest({
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_REST_API_URL}/users/search/preset/incident-contributors`,
      data: {
        filters,
        incidentId,
        ...(queryString ? { queryString } : {}),
      },
    });
    return {
      data: response.data,
      pagination: response.pagination,
    };
  } catch (error: unknown) {
    return {
      data: [],
      pagination: { ...emptyPagination },
      error: {
        message: (error as AxiosError).message,
      },
    };
  }
};

export default fetchContributors;
