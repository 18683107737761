import { useState } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { useAppSelector } from '@/hooks';
import { selectCurrentUser } from '@/store/slices/config';
import { Avatar } from '@s-rm/react-ui-lib';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIcon from '@mui/icons-material/Phone';
import { Skeleton } from '@mui/material';
import useGetPortalUrl from '@/hooks/useGetPortalUrl';
import { AvatarInnerProfile, InfoText, MenuStyled } from '../styled';
import { truncateString } from '@/utils/helpers';
import { isEmpty } from 'lodash';
import { User } from '@/api/types/user';

import MenuItem from '@mui/material/MenuItem';
import { ClearQuestionnaireDialog } from '@/components/ClearQuestionnaireDialog/ClearQuestionnaireDialog';
import IntegrationExistsDialog from '@/components/IntegrationExistsDialog/IntegrationExistsDialog';

export const ProfileMenu = ({ showSettings }: { showSettings?: boolean }) => {
  const portalRedirect = useGetPortalUrl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);
  const user = useAppSelector(selectCurrentUser) as User;
  const initials =
    `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}` || '';
  const redirectToPortal = () => (window.location.href = portalRedirect);

  const renderFullName = () => {
    const name = `${user?.firstName?.toUpperCase()} ${user?.lastName?.toUpperCase()}`;
    return truncateString(name, 40);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        size='small'
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        data-testid='profile-menu-button'
      >
        <Avatar
          sx={{
            width: 38,
            height: 38,
            fontSize: '1rem',
            background: 'grey.300',
            border: '1px solid',
            borderColor: 'grey.300',
          }}
        >
          {!user?.firstName && !user?.lastName ? (
            <Skeleton variant='circular' width={38} height={38} />
          ) : (
            initials
          )}
        </Avatar>
      </IconButton>
      {!isEmpty(user) && (
        <MenuStyled
          anchorEl={anchorEl}
          id='account-menu'
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: {
              elevation: 0,
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {!isEmpty(user) && (
            <>
              {user?.firstName && user?.lastName ? (
                <AvatarInnerProfile data-testid='inner-profile-avatar'>
                  <Avatar>{initials || ''}</Avatar>{' '}
                  <span style={{ paddingLeft: '8px' }}>
                    <b>{renderFullName()}</b>
                  </span>
                </AvatarInnerProfile>
              ) : (
                <AvatarInnerProfile data-testid='inner-profile-avatar'>
                  <Avatar>
                    <Skeleton variant='circular' width={38} height={38} />
                  </Avatar>{' '}
                  <span style={{ paddingLeft: '8px' }}>
                    <b>N/A</b>
                  </span>
                </AvatarInnerProfile>
              )}

              {(user?.emailAddress || user?.telephone) && (
                <>
                  <InfoText>
                    <b>Information</b>
                  </InfoText>
                  <Divider />
                </>
              )}

              {user?.emailAddress && (
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  disableRipple={true}
                  sx={{ cursor: 'text', userSelect: 'text', mt: 1 }}
                >
                  <ListItemIcon>
                    <AlternateEmailIcon fontSize='small' htmlColor='#FE6001' />
                  </ListItemIcon>
                  {truncateString(user?.emailAddress, 40)}
                </MenuItem>
              )}
              {user?.telephone && (
                <MenuItem
                  disableRipple={true}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  sx={{ cursor: 'text', userSelect: 'text' }}
                >
                  <ListItemIcon>
                    <PhoneIcon fontSize='small' htmlColor='#FE6001' />
                  </ListItemIcon>
                  +{user?.telephone}
                </MenuItem>
              )}
            </>
          )}

          {(user?.emailAddress || user?.telephone) && <Divider />}
          {/* <MenuItem onClick={redirectToPortal} data-testid='profile-logout-btn'>
            Log out
          </MenuItem> */}
        </MenuStyled>
      )}
      <ClearQuestionnaireDialog />
      <IntegrationExistsDialog />
    </>
  );
};
