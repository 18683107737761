import { AccessPermissions } from '@/store/types/config';

const mockPermissionsForUser = [
  'VIEW_INCIDENT',
  'VIEW_INCIDENTS',
  'VIEW_CONTRIBUTORS',
  'VIEW_ORGANISATIONS',
  'VIEW_QUESTIONNAIRES',
  'VIEW_USER_DETAIL',
  'VIEW_CLOSURE',
  'UPDATE_INSURER_PRIMARY_CONTACT',
  'VIEW_USERS_IN_CURRENT_ORG',
  'VIEW_INCIDENTS_PER_USER',
  'VIEW_INCIDENT_MANAGER_ORGS',
  'UPDATE_INCIDENT_MANAGER_ORG',
  'UPDATE_INCIDENT_MANAGER_PRIMARY_CONTACT',
  'VIEW_INCIDENT_BY_CONTRIBUTOR',
  'CREATE_INCIDENT',
  'VIEW_CLOSED_CLOSURE',
  'VIEW_CLOSED_INCIDENT',
  'VIEW_EDITOR',
  'CAN_SUBMIT_CLOSURE',
];

const getAuthorizedUserTransform = (data: any) => {
  const user = {
    fullName: data.user.fullName,
    firstName: data.user.firstName,
    lastName: data.user.lastName,
    emailAddress: data.user.emailAddress,
    telephone: data.user.telephone,
  };

  // fetched from the server
  const rolePermissions = mockPermissionsForUser;

  const filteredPermissions = Object.values(AccessPermissions).filter(
    (permissionName) => rolePermissions.includes(permissionName)
  ) as AccessPermissions[];

  return {
    user,
    permissions: filteredPermissions,
  };
};

export default getAuthorizedUserTransform;
