import React, { useState, useEffect } from 'react';
import { MultiSelectSearch, Button, LoadingButton } from '@s-rm/react-ui-lib';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  setIsOpenCreateUserFormDialog,
  selectIsCreatingUser,
} from '@/store/slices/ui';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { InputLabelRequired, TextField } from '../styled';
import { getIsValid } from '../utils/helpers';
import createUserThunk from '@/store/thunks/admin/createUserThunk';
import { renderSelectValue } from './utils/renderSelectValue';
import { userRolesOptions } from '@/data/admin';
import { NewUser } from '@/store/types/admin';

export const CreateUserFormDialog = ({
  role,
  onCreate,
  isDialogOpen,
  onCancel,
}: {
  role: 'user' | 'contributor';
  onCreate?: ({
    id,
    name,
    email,
  }: {
    id: string;
    name: string;
    email: string;
  }) => void;
  isDialogOpen: boolean;
  onCancel: () => void;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isUserCreating = useAppSelector(selectIsCreatingUser);
  const [isFormValid, setIsFormValid] = useState(false);

  const [formValues, setFormValues] = useState<NewUser>({
    organisation: role === 'user' ? 'S-RM' : '',
    email: '',
    name: '',
    role: '',
  });

  const handleInputChange = (name: string, value: any) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleOnClickCancel = () => onCancel?.();

  const handleOnClickCreate = async () => {
    if (isFormValid) {
      const { organisation, email, name, role } = formValues;
      const userId = dispatch(
        createUserThunk({ organisation, email, name, role })
      );

      onCreate?.({
        //@ts-ignore
        id: userId,
        name,
        email,
      });
    }
  };

  useEffect(() => {
    const requiredFields = [
      'email',
      'name',
      'organisation',
      ...(role === 'user' ? ['role'] : []),
    ];
    const isValid = getIsValid(formValues, requiredFields);
    setIsFormValid(isValid);
  }, [formValues, role]);

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleOnClickCancel}
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 2,
        }}
      >
        <Typography
          variant='h3'
          sx={{ fontWeight: 700 }}
          data-testid={`create-${
            role === 'user' ? 'user' : 'contributor'
          }-title`}
        >
          {role === 'user' ? 'Create new user' : 'Create new contributor'}
        </Typography>
        <IconButton aria-label='close' onClick={handleOnClickCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {role === 'user' ? (
          <>
            <FormControl variant='standard' fullWidth>
              <InputLabelRequired shrink htmlFor='organisation' required>
                Select an organisation
              </InputLabelRequired>
              <TextField
                size='small'
                required
                margin='dense'
                id='organisation'
                name='organisation'
                type='organisation'
                fullWidth
                disabled
                value={formValues.organisation}
              />
              {/* Note: For now we dont need this a simple text field will work fine */}

              {/* <MultiSelectSearch
                //@ts-ignore
                id='organisation'
                //@ts-ignore
                name='organisation'
                isMultiselect={false}
                size='small'
                inputProps={{
                  'data-testid': 'user-organisation-select',
                }}
                sx={{
                  marginTop: 2,
                  marginBottom: 3,
                  '.MuiSelect-select': {
                    padding: '8.5px 14px',
                  },
                }}
                disabled
                value={{
                  value: 1,
                  label: formValues.organisation,
                  secondaryLabel: '',
                }}
                margin='dense'
                options={[]} // Note: As of now we cannot get any other organisations outside S-RM.
                placeholder='Select organisation'
                onChange={(value) => handleInputChange('organisation', value)}
              /> */}
            </FormControl>

            <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
              <InputLabelRequired shrink htmlFor='email' required>
                Email
              </InputLabelRequired>
              <TextField
                size='small'
                required
                margin='dense'
                id='email'
                name='email'
                type='email'
                fullWidth
                placeholder='Enter email'
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                value={formValues.email}
              />
            </FormControl>

            <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
              <InputLabelRequired shrink htmlFor='name' required>
                Name
              </InputLabelRequired>
              <TextField
                size='small'
                required
                margin='dense'
                id='name'
                name='name'
                type='text'
                fullWidth
                placeholder='Enter name'
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                value={formValues.name}
              />
            </FormControl>

            <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
              <InputLabelRequired shrink htmlFor='role' required>
                User role
              </InputLabelRequired>
              <TextField
                size='small'
                required
                margin='dense'
                id='role'
                name='role'
                fullWidth
                select
                inputProps={{
                  'data-testid': 'user-role-select',
                }}
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                value={formValues.role}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: (selected) =>
                    renderSelectValue(selected, userRolesOptions),
                }}
              >
                {userRolesOptions.map((role, idx) => (
                  <MenuItem
                    key={role.value}
                    value={role.value}
                    data-testid={`list-item-user-role-${idx}`}
                  >
                    {role.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </>
        ) : (
          <>
            <FormControl variant='standard' fullWidth>
              <InputLabelRequired shrink htmlFor='organisation' required>
                Contributor organisation
              </InputLabelRequired>
              <TextField
                size='small'
                required
                margin='dense'
                id='organisation'
                name='organisation'
                type='text'
                fullWidth
                placeholder='Enter contributor organisation'
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                value={formValues.organisation}
              />
            </FormControl>

            <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
              <InputLabelRequired shrink htmlFor='email' required>
                Email
              </InputLabelRequired>
              <TextField
                size='small'
                required
                margin='dense'
                id='email'
                name='email'
                type='email'
                fullWidth
                placeholder='Enter email'
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                value={formValues.email}
              />
            </FormControl>

            <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
              <InputLabelRequired shrink htmlFor='name' required>
                Name
              </InputLabelRequired>
              <TextField
                size='small'
                required
                margin='dense'
                id='name'
                name='name'
                type='text'
                fullWidth
                placeholder='Enter name'
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                value={formValues.name}
              />
            </FormControl>
          </>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          padding: 2,
          borderTop: `1px solid ${theme.palette.grey[300]}`,
        }}
      >
        <Button onClick={handleOnClickCancel} variant='outlined'>
          Cancel
        </Button>
        <LoadingButton
          onClick={handleOnClickCreate}
          variant='contained'
          loading={isUserCreating}
          disabled={isUserCreating || !isFormValid}
          data-testid='confirmation-modal-confirm-btn'
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
