import { Box, TableContainer } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectIsLoadingContributors,
  selectContributorsEntitiesAsArray,
  updateContributorFilters,
  selectContributorsFilters,
  resetContributorsFilters,
} from '@/store/slices/admin/admin';
import { defaultFiltersObject } from '@/store/types/admin';
import { Button, Table as TableMUI } from '@s-rm/react-ui-lib';
import { useEffect } from 'react';
import { setIsAddContributorModalOpen } from '@/store/slices/ui';
import {
  getContributorsTableHeader,
  getContributorsTableRows,
} from './utils/getContributorsTableData';
import fetchContributorsThunk from '@/store/thunks/admin/fetchContributorsThunk';
import { useRouter } from 'next/router';
import { AddContributorDialog } from '../AddContributorDialog/AddContributorDialog';

const ContributorsTable = () => {
  const dispatch = useAppDispatch();
  const contributors = useAppSelector(selectContributorsEntitiesAsArray) || [];
  const isLoading = useAppSelector(selectIsLoadingContributors);
  const router = useRouter();
  const incidentId = router.query.id as string;

  const { filters, pagination, sort } = useAppSelector(
    selectContributorsFilters
  );

  const handleAddContributor = () => {
    dispatch(fetchContributorsThunk({ incidentId }));
  };

  useEffect(() => {
    dispatch(
      updateContributorFilters({
        filters: [],
      })
    );
    dispatch(fetchContributorsThunk({ incidentId }));
    return () => {
      dispatch(resetContributorsFilters());
    };
  }, [dispatch, incidentId]);

  return (
    <>
      <AddContributorDialog onAddContributor={handleAddContributor} />

      <TableContainer>
        <TableMUI
          componentProps={{
            tableProps: {
              //@ts-ignore
              'data-testid': 'contributors-table',
            },
          }}
          stickyHeader={true}
          isLoading={isLoading}
          extendedHeader={
            <Box display='flex' justifyContent='flex-end'>
              <Button
                variant='contained'
                color='primary'
                onClick={() => dispatch(setIsAddContributorModalOpen(true))}
              >
                Add contributor
              </Button>
            </Box>
          }
          header={getContributorsTableHeader()}
          rows={getContributorsTableRows({
            contributors,
          })}
          pagination={{
            paginationData: {
              total: pagination.total,
              offset: pagination.offset / pagination.limit + 1,
              limit: pagination.limit,
            },
            onChange: (newPagination) => {
              dispatch(
                updateContributorFilters({
                  pagination: {
                    offset: newPagination.offset ?? pagination.offset,
                    limit: newPagination.limit ?? pagination.limit,
                  },
                })
              );
              dispatch(fetchContributorsThunk({ incidentId }));
            },
          }}
          filters={{
            values: filters ?? [],
            onChange: (column, selectedValues) => {
              dispatch(
                updateContributorFilters({
                  filters: [{ column, values: selectedValues }],
                  pagination: defaultFiltersObject.pagination,
                })
              );
              dispatch(fetchContributorsThunk({ incidentId }));
            },
          }}
        />
      </TableContainer>
    </>
  );
};

export default ContributorsTable;
