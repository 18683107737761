import { useState } from 'react';
import { Button, Alert, AlertTitle } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { ConfirmClearQuestionnaireDialog } from './ConfirmClearQuestionnaireDialog';
import { DialogWrapper } from './styled';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectIsClearCaseDialogOpen,
  setIsClearCaseDialogOpen,
} from '@/store/slices/ui';

export const ClearQuestionnaireDialog = () => {
  const dispatch = useAppDispatch();
  const handleCloseDialog = () => {
    dispatch(setIsClearCaseDialogOpen(false));
  };
  const isOpenClearCaseDialog = useAppSelector(selectIsClearCaseDialogOpen);
  const [confirmClearQuestionnaire, setConfirmClearQuestionnaire] =
    useState(false);
  const handleOnClickContinue = () => {
    handleCloseDialog();
    setConfirmClearQuestionnaire(true);
  };

  const handleOnCloseConfirmClearCaseDialog = () =>
    setConfirmClearQuestionnaire(false);

  return (
    <>
      <DialogWrapper onClose={handleCloseDialog} open={isOpenClearCaseDialog}>
        <DialogTitle
          sx={{ m: 0, p: 3, paddingBottom: 0 }}
          data-testid='case-clear-title'
        >
          <Typography fontSize='1.3rem' fontWeight={500}>
            CLEAR CASE
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleCloseDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          data-testid='close-dialog-btn'
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography gutterBottom>
            <b>Are you sure you want to clear the case?</b>
          </Typography>
          <Alert
            severity='warning'
            sx={{
              borderLeft: '4px solid #FE5F01',
              borderRadius: '0',
              background: '#FDF4E7',
              marginTop: '16px',
            }}
          >
            <AlertTitle>Warning</AlertTitle>
            All information submitted in the <b>Case Type</b> and{' '}
            <b>Specifics</b> sections will be removed upon clearing the case.
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            autoFocus
            onClick={handleOnClickContinue}
            data-testid='clear-case-continue-btn'
            sx={{ borderRadius: '0' }}
          >
            Yes, continue
          </Button>
        </DialogActions>
      </DialogWrapper>
      {confirmClearQuestionnaire && (
        <ConfirmClearQuestionnaireDialog
          isOpen={confirmClearQuestionnaire}
          handleClose={handleOnCloseConfirmClearCaseDialog}
        />
      )}
    </>
  );
};
