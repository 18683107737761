import { emptyPagination } from '@/api/admin/types';
import { QuestionnaireMinimal } from './questionnaire';

export const defaultFiltersObject: Filters = {
  pagination: {
    ...emptyPagination,
    limit: 10,
  },
  filters: [],
  sort: { column: '', direction: '' },
  searches: [],
};

export interface SearchProps {
  column: string;
  value: string;
}

export interface Filters {
  searches: SearchProps[];
  filters: { column: string; values: string[] }[];
  sort: {
    column: string;
    direction: 'asc' | 'desc' | '';
  };
  pagination: {
    offset: number;
    limit: number;
    total: number;
    hasNext: boolean;
    hasPrev: boolean;
    page?: number;
    pages?: number;
  };
}

export enum ContributorStatus {
  Active = 'Active',
  Invited = 'Invited',
  Suspended = 'Suspended',
}

export enum ContributorActions {
  EDIT_CONTRIBUTOR = 'EDIT_CONTRIBUTOR',
  DELETE_CONTRIBUTOR = 'DELETE_CONTRIBUTOR',
}

export enum ContributorFunction {
  TA_SPECIALIST = 'TA Specialist',
  DFIR = 'DFIR',
  LEGAL = 'Legal',
}

export enum UserRole {
  User = 'User',
  Admin = 'Admin',
}

export enum UserStatus {
  Active = 'Active',
  Invited = 'Invited',
  Suspended = 'Suspended',
}

export enum ContributorRole {
  IncidentManager = 'IncidentManager',
  Insurer = 'Insurer',
  Owner = 'Owner',
}

export enum OrganisationRole {
  IncidentManager = 'INCIDENT-MANAGER',
  Insurer = 'INSURER',
}

export enum OrganisationStatus {
  Pending = 'PENDING',
}

export enum ContributorRole {
  'TA',
  'DFIR',
  'Legal',
}

export enum IncidentRole {
  PrimaryContact = 'PrimaryContact',
  Contributor = 'Contributor',
}

export enum IncidentStatus {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
}

export interface User {
  id: string;
  name: string;
  email: string;
  organisation: string;
  status: UserStatus;
  role: UserRole | UserRole[];
  createdAt?: string;
  updatedAt?: string;
  lastSeen?: string;
}

export interface NewUser {
  organisation: string;
  email: string;
  name: string;
  role: string;
}

export interface MandatoryQuestionCompletionStatus {
  visible: number;
  completedVisible: number;
}

export interface Pagination {
  offset: number;
  limit: number;
  total: number;
  next: boolean;
  previous: boolean;
}

export interface Incident {
  id: string;
  title: string;
  status: IncidentStatus;
  createdAt: string;
  closedAt: string;
  incidentManagerOrganizationName: string;
  incidentManagerPrimaryContactName: string;
  incidentOwnerOrganizationName: string;
  incidentOwnerPrimaryContactName: string;
  insurerId?: string;
  insurerPrimaryContactId?: string;
  managerOrgId?: string;
  managerOrgPrimaryContactId?: string;
  caseTypes?: string[];
  role?: IncidentRole;
  completionRate?: number;
  // GET incident/{id} view only
  description?: string;
  victimOrg?: string;
  intake?: {
    id: string;
    startDate: string;
    endDate: string;
    caseType: string[];
    status: IncidentStatus;
  };
  closure?: {
    id: string;
    startDate: string;
    endDate: string;
    status: IncidentStatus;
    completionRate: number;
  };
}

export interface Organisation {
  id: string;
  name: string;
  roles: OrganisationRole[];
  externalId?: string;
  shortName?: string;
  sector?: string;
  domainList?: string;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
}

export interface Contributor {
  id: string;
  email: string;
  name: string;
  organization: string;
  status: ContributorStatus;
  roles: ContributorFunction[];
  completionRate: number;
}

export interface AdminSlice {
  questionnaires: {
    ids: string[];
    entities: {
      [key: string]: QuestionnaireMinimal;
    };
  };
  incidents: {
    ids: string[];
    entities: {
      [key: string]: Incident;
    };
    filters: Filters;
    isLoading: boolean;
  };
  contributors: {
    ids: string[];
    entities: {
      [key: string]: Contributor;
    };
    filters: Filters;
    isLoading: boolean;
  };
  organisations: {
    ids: string[];
    entities: {
      [key: string]: Organisation;
    };
    filters: Filters;
    isLoading: boolean;
    isUpdating: boolean;
  };
  users: {
    ids: string[];
    entities: {
      [key: string]: User;
    };
    filters: Filters;
    isLoading: boolean;
  };
}
