import { ReactNode } from 'react';

export enum SnackbarTypes {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}

export interface PendingInterceptAction {
  action: string;
  payload: any;
}

export enum AccessPermissionsNames {
  ViewIncident = 'viewIncident',
  ViewIncidents = 'viewIncidents',
  ViewContributors = 'viewContributors',
  ViewOrganisations = 'viewOrganisations',
  ViewQuestionnaires = 'viewQuestionnaires',
  ViewUserDetail = 'viewUserDetail',
  ViewClosure = 'viewClosure',
  UpdateInsurerPrimaryContact = 'updateInsurerPrimaryContact',
  ViewUsersInCurrentOrg = 'viewUsersInCurrentOrg',
  ViewIncidentsPerUser = 'viewIncidentsPerUser',
  ViewIncidentManagerOrgs = 'viewIncidentManagerOrgs',
  UpdateIncidentManagerOrg = 'updateIncidentManagerOrg',
  UpdateIncidentManagerPrimaryContact = 'updateIncidentManagerPrimaryContact',
  ViewIncidentByContributor = 'viewIncidentByContributor',
  CreateIncident = 'createIncident',
  ViewClosedClosure = 'viewClosedClosure',
  ViewClosedIncident = 'viewClosedIncident',
  ViewEditor = 'viewEditor',
  CanSubmitClosure = 'canSubmitClosure',
}
export interface AccessPermissions {
  [AccessPermissionsNames.ViewIncident]: boolean;
  [AccessPermissionsNames.ViewIncidents]: boolean;
  [AccessPermissionsNames.ViewContributors]: boolean;
  [AccessPermissionsNames.ViewOrganisations]: boolean;
  [AccessPermissionsNames.ViewQuestionnaires]: boolean;
  [AccessPermissionsNames.ViewUserDetail]: boolean;
  [AccessPermissionsNames.ViewClosure]: boolean;
  [AccessPermissionsNames.UpdateInsurerPrimaryContact]: boolean;
  [AccessPermissionsNames.ViewUsersInCurrentOrg]: boolean;
  [AccessPermissionsNames.ViewIncidentsPerUser]: boolean;
  [AccessPermissionsNames.ViewIncidentManagerOrgs]: boolean;
  [AccessPermissionsNames.UpdateIncidentManagerOrg]: boolean;
  [AccessPermissionsNames.UpdateIncidentManagerPrimaryContact]: boolean;
  [AccessPermissionsNames.ViewIncidentByContributor]: boolean;
  [AccessPermissionsNames.CreateIncident]: boolean;
  [AccessPermissionsNames.ViewClosedClosure]: boolean;
  [AccessPermissionsNames.ViewClosedIncident]: boolean;
  [AccessPermissionsNames.ViewEditor]: boolean;
  [AccessPermissionsNames.CanSubmitClosure]: boolean;
}

export interface UiSlice {
  scrollPositions: {
    [key: string]: number;
  };
  printableSummaryStatus: {
    isOpen: boolean;
    isAutoCopy: boolean;
  };
  snackbarNotification: {
    message: ReactNode;
    action: ReactNode;
    type: SnackbarTypes;
    disableAutoHide: boolean;
  };
  refreshDialogStatus: {
    isOpen: boolean;
  };
  descriptionPopoutWindowStatus: {
    isOpen: boolean;
  };
  suggestedIndicatorsStatus: {
    isLoading: boolean;
  };
  isRefreshingDisplay: boolean;
  isEditMode: boolean;
  suggestedAnswersFetchingStatus: {
    [key: string]: {
      isLoading?: boolean;
      hasError?: boolean;
      hasSucceeded?: boolean;
    };
  };
  hasRequestedSuggestedAnswers: boolean;
  hasQuestionnaireBeenCleared: boolean;
  hasDismissedQuestionnaireTimeDialog: boolean;
  caseClosureId: string | null;
  isOpenClearCaseDialog: boolean;
  isDescriptionVisible: boolean;
  isConfirmingCaseClosure: boolean;
  isConfirmingFinishQuestionnaire: boolean;
  isConfirmingReopenCaseClosure: boolean;
  isConfirmingReopenQuestionnaire: boolean;
  isClosureSummaryDialogOpen: boolean;
  pendingInterceptAction: PendingInterceptAction[];
  isOpenQuestionnaireTimeDialog: boolean;
  activePath: string;
  isOpenCaseClosureTimeDialog: boolean;
  hasDismissedCaseClosureTimeDialog: boolean;
  hasTriggeredIntegrationDialogOpen: {
    isOpen: boolean;
    integrationName: string;
  };
  isOpenCreateIncidentDialog: boolean;
  isContributorsListDialogOpen: boolean;
  isCreatingIncident: boolean;
  isCreatingUser: boolean;
  isOpenCreateUserFormDialog: boolean;
  isOpenCreateOrganisationDialog: boolean;
  isCreatingOrganisation: boolean;
  isAddContributorModalOpen: boolean;
  isCreateUserModalOpen: boolean;
  organisationDialog: {
    isOpen: boolean;
    isEditMode: boolean;
    organisationId?: string;
  };
}
