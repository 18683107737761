import { useState } from 'react';
import Menu from '@mui/material/Menu';
import {
  selectIsEditMode,
  setIsCaseClosureTimeDialogOpen,
  setIsQuestionnaireTimeDialogOpen,
  setIsClearCaseDialogOpen,
} from '@/store/slices/ui';
import { setIsConfrimUseEditorModalOpen } from '@/store/slices/editor';
import { useAppSelector, useAppDispatch } from '@/hooks';
import { MenuItemStyled, StyledIconButton, StyledSettingsIcon } from './styled';
import Image from 'next/image';
import { JIRA_SVG_PATH, SENTINEL_ONE_SVG_PATH } from '@/constants/svgPaths';
import triggerIntegrationThunk from '@/store/thunks/triggerIntegrationThunk';
import { IntegrationName } from '@/store/types/integrations';
import useHasAuthorization from '@/hooks/useHasAuthorization';
import { AccessPermissions } from '@/store/types/config';

export const SettingsMenu = () => {
  const dispatch = useAppDispatch();
  const isEditMode = useAppSelector(selectIsEditMode);
  const [anchorSettingsMenu, setAnchorSettingsMenu] =
    useState<null | HTMLElement>(null);
  const canViewEditor = useHasAuthorization({
    requiredPermissions: [AccessPermissions.ViewEditor],
  });
  const isOpenSettingsMenu = Boolean(anchorSettingsMenu);
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorSettingsMenu(event.currentTarget);
  const handleCloseMenu = () => setAnchorSettingsMenu(null);

  const handleClickOpenClearCaseDialog = () => {
    dispatch(setIsClearCaseDialogOpen(true));
    handleCloseMenu();
  };
  const handleClickOpenEditor = () => {
    dispatch(setIsConfrimUseEditorModalOpen(true));
    handleCloseMenu();
  };

  const handleClickTriggerJiraIssue = () =>
    dispatch(
      triggerIntegrationThunk({ integrationName: IntegrationName.JIRA })
    );

  const handleClickTriggerS1Site = () =>
    dispatch(triggerIntegrationThunk({ integrationName: IntegrationName.S1 }));

  return (
    <>
      <StyledIconButton
        aria-label='icon-button-settings'
        color='primary'
        onClick={handleClickMenu}
        data-testid='icon-btn-settings'
        isEditMode={isEditMode}
      >
        <StyledSettingsIcon
          isEditMode={isEditMode}
          data-testid='settings-menu-button'
        />
      </StyledIconButton>
      <Menu
        anchorEl={anchorSettingsMenu}
        open={isOpenSettingsMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          //@ts-ignore
          'data-testid': 'settings-menu',
        }}
      >
        <MenuItemStyled
          onClick={() => {
            dispatch(setIsQuestionnaireTimeDialogOpen(true));
            handleCloseMenu();
          }}
        >
          Edit Intake Time
        </MenuItemStyled>
        <MenuItemStyled
          onClick={() => {
            dispatch(setIsCaseClosureTimeDialogOpen(true));
            handleCloseMenu();
          }}
        >
          Edit Closure Time
        </MenuItemStyled>
        <MenuItemStyled
          onClick={handleClickOpenClearCaseDialog}
          data-testid='clear-case-btn'
        >
          Clear Case
        </MenuItemStyled>
        {isEditMode ? (
          <MenuItemStyled
            onClick={handleClickOpenEditor}
            data-testid='close-editor-btn'
          >
            Close Editor
          </MenuItemStyled>
        ) : (
          <>
            {canViewEditor && (
              <MenuItemStyled
                onClick={handleClickOpenEditor}
                data-testid='open-editor-btn'
              >
                Open Editor
              </MenuItemStyled>
            )}
          </>
        )}
        <MenuItemStyled
          onClick={handleClickTriggerJiraIssue}
          data-testid='trigger-jira-integration'
        >
          <Image
            src={JIRA_SVG_PATH}
            alt='jira-logo'
            width={20}
            height={20}
            style={{ marginRight: 8 }}
          />
          Create Jira Ticket
        </MenuItemStyled>
        <MenuItemStyled
          onClick={handleClickTriggerS1Site}
          style={{ justifyContent: 'start' }}
          data-testid='trigger-sentinelone-integration'
        >
          <Image
            src={SENTINEL_ONE_SVG_PATH}
            alt='sentinelone-logo'
            width={20}
            height={20}
            style={{ marginRight: 8 }}
          />
          Create S1 Site
        </MenuItemStyled>
      </Menu>
    </>
  );
};
