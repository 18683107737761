import { AppThunk } from '../store';
import {
  setIsConfirmingReopenCase,
  setIsConfirmingReopenQuestionnaire,
  setPendingInterceptAction,
} from '../slices/ui';
import updateQuestionValueThunk from './updateQuestionValue';
import activateCaseThunk from './activateCase';
import { PendingInterceptAction } from '../types/ui';
import updateDescriptionThunk from './updateDescription';
import addSelectedCaseTypesThunk from './addSelectedCaseTypes';
import removeSelectedCaseTypesThunk from './removeSelectedCaseTypes';
import addSelectedModulesThunk from './addSelectedModules';
import {
  addManuallySelectedModules,
  removeManuallySelectedModules,
  removeSelectedModules,
} from '../slices/modules';
import changeIndicatorStateThunk from './changeIndicatorStateThunk';
import { ActionNames } from '../types/actions';
import {
  setClosureTime,
  setQuestionnaireStartedAt,
} from '../slices/questionnaire';
import activateQuestionnaire from './activateQuestionnaire';
import { Routes } from '@/constants/routes';
import { getFeatureFlagFromStorage } from '@/components/FeatureFlags/utils/checkAndSetFeatureFlag';

const invokePendingInterceptAction =
  (callbackData: { action: string; payload: any }): AppThunk =>
  (dispatch) => {
    switch (callbackData.action) {
      case ActionNames.UpdateQuestionValue:
        dispatch(updateQuestionValueThunk(callbackData.payload));
        break;
      case ActionNames.UpdateDescription:
        dispatch(updateDescriptionThunk(callbackData.payload));
        break;
      case ActionNames.AddSelectedCaseTypes:
        dispatch(addSelectedCaseTypesThunk(callbackData.payload));
        break;
      case ActionNames.RemoveSelectedCaseTypes:
        dispatch(removeSelectedCaseTypesThunk(callbackData.payload));
        break;
      case ActionNames.AddSelectedModules:
        dispatch(addSelectedModulesThunk(callbackData.payload));
        break;
      case ActionNames.AddManuallySelectedModules:
        dispatch(addManuallySelectedModules(callbackData.payload));
        break;
      case ActionNames.RemoveManuallySelectedModules:
        dispatch(removeManuallySelectedModules(callbackData.payload));
        break;
      case ActionNames.RemoveSelectedModules:
        dispatch(removeSelectedModules(callbackData.payload));
        break;
      case ActionNames.ChangeIndicatorState:
        dispatch(changeIndicatorStateThunk(callbackData.payload));
        break;
    }
  };

const userActionInterceptor =
  (interceptActions: {
    actions: PendingInterceptAction[];
    shouldContinue?: boolean;
    shouldContinueIntake?: boolean;
  }): AppThunk =>
  (dispatch) => {
    const IS_USING_QUESTIONNAIRE_API = getFeatureFlagFromStorage(
      'IS_USING_QUESTIONNAIRE_API'
    );
    if (IS_USING_QUESTIONNAIRE_API) {
      dispatch(betaUserActionInterceptor(interceptActions));
    } else {
      dispatch(legacyUserActionInterceptor(interceptActions));
    }
  };

const legacyUserActionInterceptor =
  (interceptActions: {
    actions: PendingInterceptAction[];
    shouldContinue?: boolean;
    shouldContinueIntake?: boolean;
  }): AppThunk =>
  (dispatch, getState) => {
    const isClosureActive = getState().questionnaire.isActive;
    const isQuestionnaireActive = getState().questionnaire.isIntakeActive;
    const currentRoute = getState().ui.activePath;

    const {
      actions,
      shouldContinue: shouldContinueClosure,
      shouldContinueIntake,
    } = interceptActions;

    const isQuestionnaireRoute = currentRoute !== Routes.Closure;
    const isClosureRoute = currentRoute === Routes.Closure;

    const isCaseClosureActive = isClosureRoute && isClosureActive;

    const isQuestionnaireActiveInRoute =
      isQuestionnaireRoute && isQuestionnaireActive;
    const shouldProceed = isCaseClosureActive || isQuestionnaireActiveInRoute;

    if (shouldProceed) {
      actions.forEach((actionObj) => {
        dispatch(invokePendingInterceptAction(actionObj));
      });
      return;
    }

    if (shouldContinueIntake) {
      const storedActions = getState().ui.pendingInterceptAction;
      storedActions.forEach((actionObj) => {
        dispatch(invokePendingInterceptAction(actionObj));
      });
      dispatch(setPendingInterceptAction([]));
      dispatch(setQuestionnaireStartedAt(null));
      dispatch(activateQuestionnaire());
      return;
    }

    // Please note: this will become depricated and removed in the future.
    if (shouldContinueClosure) {
      const storedActions = getState().ui.pendingInterceptAction;
      storedActions.forEach((actionObj) => {
        dispatch(invokePendingInterceptAction(actionObj));
      });
      dispatch(setPendingInterceptAction([]));
      // Clear the case closure time and re-open the case
      dispatch(setClosureTime(null));
      dispatch(activateCaseThunk());
      return;
    }

    // If neither the case or intake is NOT active or NOT continuing, store the action and payload
    dispatch(setPendingInterceptAction(actions));

    currentRoute === Routes.Closure
      ? dispatch(setIsConfirmingReopenCase(true))
      : dispatch(setIsConfirmingReopenQuestionnaire(true));
  };

// Note: For now we maintain two userActionInterceptors the legacy one supports the old intake/closure API
// The beta implementation will replace the above when the questionnaire API is fully functioning and has been tested by QA.
const betaUserActionInterceptor =
  (interceptActions: {
    actions: PendingInterceptAction[];
    shouldContinue?: boolean;
    shouldContinueIntake?: boolean;
  }): AppThunk =>
  (dispatch, getState) => {
    const isQuestionnaireActive = getState().questionnaire.isActive;
    const {
      actions,
      shouldContinue: shouldContinueClosure,
      shouldContinueIntake,
    } = interceptActions;

    if (isQuestionnaireActive) {
      actions.forEach((actionObj) => {
        dispatch(invokePendingInterceptAction(actionObj));
      });
      return;
    }

    // Note: this will become depricated and there would just be one action to re-open the questionnaire.
    if (shouldContinueClosure || shouldContinueIntake) {
      const storedActions = getState().ui.pendingInterceptAction;
      storedActions.forEach((actionObj) => {
        dispatch(invokePendingInterceptAction(actionObj));
      });
      dispatch(setPendingInterceptAction([]));
      dispatch(setQuestionnaireStartedAt(null));
      dispatch(activateQuestionnaire());
      return;
    }

    dispatch(setPendingInterceptAction(actions));
    dispatch(setIsConfirmingReopenQuestionnaire(true));
  };

export default userActionInterceptor;
