import { makeRequest } from '@/api/axios/client';
import { AxiosError } from 'axios';
import getAuthorizedUserTransform from '../transforms/auth/getAuthorizedUserTransform';
import mockAuthorizeResponse from '@/__mocks__/api/mockAuthorizeResponse';

const authorizeUser = async () => {
  // mock for now
  return Promise.resolve({
    data: getAuthorizedUserTransform(mockAuthorizeResponse),
    error: null,
  });

  // try {
  //   const response = await makeRequest({
  //     method: 'POST',
  //     url: `${process.env.NEXT_PUBLIC_REST_API_URL}/authorize`,
  //   });

  //   return {
  //     data: response.data,
  //     error: null,
  //   };
  // } catch (error: unknown) {
  //   return {
  //     data: null,
  //     error: {
  //       message: (error as AxiosError).message,
  //     },
  //   };
  // }
};

export default authorizeUser;
