'use-client';
import { useContext } from 'react';
import { Box, Container, Typography, createTheme } from '@mui/material';
import NavBar from '../../NavBar/NavBarLegacy';
import { ThemeProvider, darkTheme, lightTheme } from '@s-rm/react-ui-lib';
import logo from '../../../assets/img/logo.png';
import Image from 'next/image';
import {
  HeaderWrapper,
  HeaderFlexContainer,
  LogoWrapper,
  NavBarWrapper,
  AvatarWrapper,
} from '../styled';
import { useAppSelector } from '@/hooks';
import { selectIsEditMode } from '@/store/slices/ui';
import { ProfileMenuLegacy } from '../ProfileMenuLegacy';
import { NetworkMessages } from '../NetworkMessages';
import { OnlineStatusContext } from '@/context/onlineStatusContext';
import { getIsFeatureFlagRoute } from '@/utils/router';
import { useRouter } from 'next/router';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import { selectIsDiagnosisEnabled } from '@/store/slices/questionnaire';
import { selectCurrentUser } from '@/store/slices/config';
import { User } from '@/api/types/user';
const light = createTheme(lightTheme);
const dark = createTheme(darkTheme);

const QuestionnaireHeader = (): JSX.Element => {
  const isEditMode = useAppSelector(selectIsEditMode);
  const theme = isEditMode ? light : dark;
  const { isOnline, wasOffline } = useContext(OnlineStatusContext);
  const router = useRouter();
  const isFeatureFlagRoute = getIsFeatureFlagRoute(router);
  const isDiagnosisEnabled = useAppSelector(selectIsDiagnosisEnabled);
  const currentUser = useAppSelector(selectCurrentUser) as User;

  return (
    <>
      <HeaderWrapper isEditMode={isEditMode}>
        <Container maxWidth='xl'>
          <HeaderFlexContainer>
            <LogoWrapper>
              <Image src={logo} alt='srm-logo' />
              {isEditMode && (
                <Typography mt={0.5} fontStyle='italic'>
                  Editor
                </Typography>
              )}
            </LogoWrapper>
            {isFeatureFlagRoute ? (
              <Box display='flex' alignItems='center' gap={2}>
                <Typography variant='h5'>Feature Flags</Typography>
                <FlagOutlinedIcon />
              </Box>
            ) : (
              <NavBarWrapper>
                <ThemeProvider theme={theme}>
                  {!isDiagnosisEnabled ? (
                    <Typography variant='body1' sx={{ textAlign: 'center' }}>
                      {currentUser?.fullName
                        ? `${currentUser?.fullName}'s questionnaire`
                        : `Questionnaire page`}
                    </Typography>
                  ) : (
                    <NavBar />
                  )}
                </ThemeProvider>
              </NavBarWrapper>
            )}
            <AvatarWrapper>
              <ProfileMenuLegacy showSettings />
            </AvatarWrapper>
          </HeaderFlexContainer>
        </Container>
      </HeaderWrapper>
      {!isOnline && NetworkMessages.Offline()}
      {wasOffline && isOnline && NetworkMessages.Reconnect()}
    </>
  );
};

export default QuestionnaireHeader;
