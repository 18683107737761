import { RawQuestionnairesForProjectResponse } from '@/api/types/questionnaire';
import { QuestionnaireMinimal } from '@/store/types/questionnaire';

export const getFormattedMinimalQuestionnaires = (
  questionnaires: RawQuestionnairesForProjectResponse[]
): QuestionnaireMinimal[] => {
  return questionnaires.map((q: any) => ({
    id: q.id,
    active: q.active,
    startedAt: q.startedAt,
    completedAt: q.completedAt,
    label: q.config.label,
    projectId: q.projectId,
    projectName: q.project.name || '',
  }));
};
