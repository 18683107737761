import {
  AdminSlice,
  defaultFiltersObject,
  Organisation,
} from '@/store/types/admin';
import {
  UpdateFilterPayload,
  updateFilters,
} from '@/store/utils/updateFilters';
import { PayloadAction } from '@reduxjs/toolkit';

const organisationsReducers = {
  setOrganisations: (
    state: AdminSlice,
    action: PayloadAction<Organisation[]>
  ) => {
    state.organisations.entities = action.payload.reduce((acc, org) => {
      acc[org.id] = org;
      return acc;
    }, {} as Record<string, Organisation>);
    state.organisations.ids = action.payload.map((v) => v.id);
  },
  updateOrganisationFilters: (
    state: AdminSlice,
    action: PayloadAction<UpdateFilterPayload>
  ) => {
    state.organisations.filters = updateFilters(
      state.organisations.filters,
      action.payload
    );
  },
  setIsLoadingOrganisations: (
    state: AdminSlice,
    action: PayloadAction<boolean>
  ) => {
    state.organisations.isLoading = action.payload;
  },
  setIsUpdatingOrganisation: (
    state: AdminSlice,
    action: PayloadAction<boolean>
  ) => {
    state.organisations.isUpdating = action.payload;
  },
  resetOrganisationsFilters: (state: AdminSlice) => {
    state.organisations.filters = defaultFiltersObject;
  },
};

export default organisationsReducers;
