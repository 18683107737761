import client from '@/api/client';
//@ts-ignore
import { UpdateCase, UpdateQuestionnaire } from '@/api/gql/mutations.gql';
import { ErrorCodes } from '@/constants/errors';
import { store } from '@/store';
import { createError } from '@/utils/errorHandler';
import { getSelectedCaseTypesToSave } from '../utils/getSelectedCaseTypesToSave';
import { getFeatureFlagFromStorage } from '@/components/FeatureFlags/utils/checkAndSetFeatureFlag';

export interface UpdateCaseSaveData {
  selectedIndicatorIds?: string[];
  dismissedIndicatorIds?: string[];
  selectedCaseTypes?: string[];
}

export const updateQuestionnaire = async (questionnaireId?: string) => {
  const caseTypes = store.getState().caseTypes.entities;
  const IS_USING_QUESTIONNAIRE_API = getFeatureFlagFromStorage(
    'IS_USING_QUESTIONNAIRE_API'
  );

  let variables = {};

  if (IS_USING_QUESTIONNAIRE_API) {
    variables = {
      id: store.getState().questionnaire.questionnaireId,
      projectId: store.getState().questionnaire.projectId,
      notes: store.getState().questionnaire.description,
      startedAt: store.getState().questionnaire.startedAt,
      completedAt: store.getState().questionnaire.completedAt,
      isActive: store.getState().questionnaire.isActive,
      triggeredIntegrations:
        store.getState().questionnaire.triggeredIntegrations,
      config: {
        label: store.getState().questionnaire.label,
        staticModuleIds: store.getState().modules.staticModuleIds,
        features: {
          diagnosis: {
            toggled: store.getState().questionnaire.isDiagnosisEnabled,
            selectedIndicatorIds: store.getState().indicators.selected,
            dismissedIndicatorIds: store.getState().indicators.dismissed,
            selectedCaseTypeIds: getSelectedCaseTypesToSave(caseTypes, store),
          },
          addModules: {
            toggled: store.getState().questionnaire.isAddModulesEnabled,
            manuallySelectedModules: store.getState().modules.manuallySelected,
          },
          addNotes: {
            toggled: store.getState().questionnaire.isNotesEnabled,
          },
        },
      },
    };
  } else {
    variables = {
      caseId: store.getState().questionnaire.projectId,
      selectedIndicatorIds: store.getState().indicators.selected,
      dismissedIndicatorIds: store.getState().indicators.dismissed,
      selectedCaseTypes: getSelectedCaseTypesToSave(caseTypes, store),
      description: store.getState().questionnaire.description,
      selectedModules: store.getState().modules.manuallySelected,
      isActive: store.getState().questionnaire.isActive,
      isIntakeActive: store.getState().questionnaire.isIntakeActive,
      intakeTime: store.getState().questionnaire.intakeTime,
      closureTime: store.getState().questionnaire.closureTime,
      triggeredIntegrations:
        store.getState().questionnaire.triggeredIntegrations,
    };
  }

  try {
    const { data, errors } = await client.mutate({
      mutation: IS_USING_QUESTIONNAIRE_API ? UpdateQuestionnaire : UpdateCase,
      variables,
    });

    if (errors) {
      createError({
        code: ErrorCodes.UpdateCase,
        error: new Error(JSON.stringify(errors)),
        displaySnackbar: true,
      });
    }

    return { data, errors };
  } catch (error) {
    createError({
      code: ErrorCodes.UpdateCase,
      displaySnackbar: true,
    });
  }
};

export default updateQuestionnaire;
