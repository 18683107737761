import {
  ContributorStatus,
  ContributorFunction,
  defaultFiltersObject,
} from '@/store/types/admin';

export const mockContributor1 = {
  id: '1',
  email: 'jdoe@sentinel.io',
  name: 'John Doe',
  organization: 'S-RM',
  status: ContributorStatus.Active,
  roles: [ContributorFunction.DFIR],
  completionRate: 100,
};

export const mockContributor2 = {
  id: '2',
  email: 'asmith@sentinel.io',
  name: 'Alice Smith',
  organization: 'S-RM',
  status: ContributorStatus.Active,
  roles: [ContributorFunction.LEGAL],
  completionRate: 69,
};

export const mockContributor3 = {
  id: '3',
  email: 'bjackson@sentinel.io',
  name: 'Bob Jackson',
  organization: 'S-RM',
  status: ContributorStatus.Suspended,
  roles: [ContributorFunction.TA_SPECIALIST],
  completionRate: 0,
};

export const mockContributor4 = {
  id: '4',
  email: 'cmiller@nullbyte.com',
  name: 'Charlotte Miller',
  organization: 'S-RM',
  status: ContributorStatus.Active,
  roles: [
    ContributorFunction.DFIR,
    ContributorFunction.LEGAL,
    ContributorFunction.TA_SPECIALIST,
  ],
  completionRate: 78,
};

export const mockContributor5 = {
  id: '5',
  email: 'djones@nullbyte.com',
  name: 'David Jones',
  organization: 'S-RM',
  status: ContributorStatus.Invited,
  roles: [ContributorFunction.TA_SPECIALIST, ContributorFunction.LEGAL],
  completionRate: 54,
};

export const mockContributor6 = {
  id: '6',
  email: 'elewis@nullbyte.com',
  name: 'Emma Lewis',
  organization: 'S-RM',
  status: ContributorStatus.Active,
  roles: [ContributorFunction.DFIR],
  completionRate: 100,
};

export const mockContributor7 = {
  id: '7',
  email: 'fmoore@delta-insx.net',
  name: 'Frank Moore',
  organization: 'S-RM',
  status: ContributorStatus.Active,
  roles: [ContributorFunction.TA_SPECIALIST],
  completionRate: 0,
};

export const mockContributor8 = {
  id: '8',
  email: 'gmartin@s-rminform.com',
  name: 'Grace Martin',
  organization: 'S-RM',
  status: ContributorStatus.Suspended,
  roles: [ContributorFunction.LEGAL, ContributorFunction.TA_SPECIALIST],
  completionRate: 40,
};

export const mockContributor9 = {
  id: '9',
  email: 'hlee@s-rminform.com',
  name: 'Henry Lee',
  organization: 'S-RM',
  status: ContributorStatus.Invited,
  roles: [ContributorFunction.DFIR],
  completionRate: 10,
};

export const mockContributor10 = {
  id: '10',
  email: 'imorgan@s-rminform.com',
  name: 'Ivy Morgan',
  organization: 'S-RM',
  status: ContributorStatus.Active,
  roles: [ContributorFunction.LEGAL, ContributorFunction.DFIR],
  completionRate: 80,
};

export const mockContributors = {
  entities: {
    [mockContributor1.id]: mockContributor1,
  },
  ids: [mockContributor1.id],
  filters: defaultFiltersObject,
  isLoading: false,
};
