import { getApiToken, getIsUsingApiKey } from '@/utils/auth';
import axios, {
  AxiosError,
  AxiosRequestHeaders,
  AxiosResponse,
  Method,
} from 'axios';

export const getClient = () => {
  const shouldUseApiKey = getIsUsingApiKey();
  const token = getApiToken();

  return axios.create({
    headers: {
      ...(shouldUseApiKey && { 'x-api-key': token }),
      ...(!shouldUseApiKey && {
        Authorization: `Bearer ${token}`,
      }),
    },
  });
};

interface MakeRequestOptions<T = any> {
  method?: Method;
  url: string;
  headers?: AxiosRequestHeaders;
  params?: Record<string, any>;
  data?: T;
}

export const makeRequest = async <T = any, R = any>({
  method = 'GET',
  url,
  headers = {} as AxiosRequestHeaders,
  params = {},
  data = {} as T,
}: MakeRequestOptions<T>): Promise<R> => {
  const client = getClient();

  try {
    const response: AxiosResponse<R> = await client({
      method,
      url,
      headers: {
        ...headers,
      },
      params,
      data,
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      console.error('Axios error:', axiosError.message);
    } else {
      console.error('Unknown error:', error);
    }
    throw error;
  }
};
